/**
 * NotificationSettingsPage is a React component that renders a page for managing notification settings.
 *
 * This component provides the following features:
 * - Display and edit notification settings such as receiving notifications, notification method, email and phone number.
 * - Display a list of previous notifications with sortable sent time.
 * - Pagination for the list of notifications.
 * - A modal to show more details about a notification.
 * - Sorting of notifications based on selected field in ascending or descending order.
 * - Display of notifications for the current page based on the selected items per page.
 *
 */
import { jwtDecode } from "jwt-decode";
import React, { useCallback, useContext, useEffect, useState } from "react";
import UserContext from "../../../context/UserContext";
import "./NotificationSettingsPage.scss";
import { getAllThresholdViolationMeasurements } from "../../../api/sensorApi";
import NotificationTable from "./NotificationTable/NotificationTable";
import NotificationModal from "./NotificationModal/NotificationModal";
import USER_ROLES from "../../../constants/userRoles";
import useTranslation from "../../../hooks/useTranslation";
import PageHeader from "../../Shared/PageHeader/PageHeader";
import {
  getEmailNotificationSettingsById,
  updateUserEmailNotificationSettingsById,
} from "../../../api/settingsUserApi";
import { toast } from "react-toastify";

export const ToggleButton = ({ title, value, onSave }) => {
  const [isToggled, setIsToggled] = useState(value);

  useEffect(() => {
    setIsToggled(value);
  }, [value]);

  const handleToggle = () => {
    const newValue = !isToggled;
    setIsToggled(newValue);
    onSave(newValue);
  };

  return (
    <div className="toggle-container">
      <span>{title}</span>
      <div
        className={`toggle-switch ${isToggled ? "toggled" : ""}`}
        onClick={handleToggle}
      >
        <div className="toggle-knob"></div>
      </div>
    </div>
  );
};

const NotificationSettingsPage = () => {
  const { user } = useContext(UserContext);
  const { t } = useTranslation("notificationSettingsPage");

  const [userEmailNotificationSetting, setUserEmailNotificationSetting] =
    useState("");
  // Modal state
  const [showNotificationInfoModal, setShowNotificationInfoModal] =
    useState(false);
  const [selectedNotification, setSelectedNotification] = useState(null);
  // Table content
  const [notificationTableHeaders, setNotificationTableHeaders] = useState({});
  const [notificationTableData, setNotificationTableData] = useState([]);
  // Sort state
  const [sortField, setSortField] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);
  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);

  // Extract user role from token outside of useEffect to prevent unnecessary re-renders
  const token = localStorage.getItem("token");
  const decodedToken = token ? jwtDecode(token) : {};
  const userRole = decodedToken.role || "";

  // Define headers once and filter based on role
  const getTableHeaders = useCallback((t, role) => {
    const baseHeaders = {
      sens_timestamp: t("sensTimestamp"),
      probeid: t("probeId"),
      sens_type: t("sensorType"),
      message: t("message"),
      user: t("notification_page_received_user"),
      acknowledged_at: t("notification_page_acknowledged_at"),
      acknowledged_by: t("notification_page_acknowledged_user"),
      notificationMoreInfo: "",
    };

    // Filter headers based on user role
    if (role === USER_ROLES.COMPANY_ADMIN) {
      delete baseHeaders.companyname;
    } else if (role === USER_ROLES.COMPANYUSER) {
      delete baseHeaders.sitename;
      delete baseHeaders.companyname;
      delete baseHeaders.user;
      delete baseHeaders.acknowledged_by;
    }
    return baseHeaders;
  }, []);

  useEffect(() => {
    if (userRole) {
      const headers = getTableHeaders(t, userRole);
      setNotificationTableHeaders(headers);
    }
  }, [userRole, t, getTableHeaders]);

  // EMAIL NOTIFICATION FETCH AND UPDATE

  // Fetch user email notification setting
  const fetchUserEmailNotificationSetting = async () => {
    try {
      // Fetch user email notification setting from the API
      const data = await getEmailNotificationSettingsById();
      console.log("User email notification setting: ", data);
      setUserEmailNotificationSetting(data);
    } catch (error) {
      console.error("Error fetching user email notification setting:", error);
    }
  };

  const handleUpdateUserEmailNotificationSetting = async (
    emailNotification
  ) => {
    // Update user email notification setting
    try {
      // Update user email notification setting
      await updateUserEmailNotificationSettingsById(emailNotification);
      // Update local state
      setUserEmailNotificationSetting(emailNotification);
      // Show success message
      toast.success(
        t("notification_page_email_notification_setting_updated_successfully")
      );
    } catch (error) {
      console.error("Error updating user email notification setting:", error);
      // Show error message
      toast.error(
        t("notification_page_email_notification_setting_update_error")
      );
    }
  };

  // Number of items per page
  const itemsPerPage = 5;

  // Fetch notification data
  const fetchNotificationData = async () => {
    try {
      // Fetch notification data from the API
      const data = await getAllThresholdViolationMeasurements();
      console.log("Notification data: ", data);
      const processedData = data.map((item) => {
        let message = "";
        if (item.sens_type === 2) {
          item.sens_type = t("temperature");
          if (item.sens_value > item.max_value) {
            message = t("temperatureTooHigh");
          } else if (item.sens_value < item.min_value) {
            message = t("temperatureTooLow");
          }
        }
        return { ...item, message };
      });
      setNotificationTableData(processedData);
    } catch (error) {
      console.error("Error fetching notification data:", error);
    }
  };

  useEffect(() => {
    fetchUserEmailNotificationSetting();
    fetchNotificationData();
  }, []);

  const handleNotificationInfoModalShow = (notification) => {
    setSelectedNotification(notification);
    setShowNotificationInfoModal(true);
  };

  const handleNotificationInfoModalClose = () => {
    setShowNotificationInfoModal(false);
  };

  const handleSort = (field) => {
    let direction = "asc";
    if (sortField === field && sortDirection === "asc") {
      direction = "desc";
    }
    setSortField(field);
    setSortDirection(direction);
  };

  const sortedData = [...notificationTableData].sort((a, b) => {
    if (sortField) {
      if (sortField === "sens_timestamp") {
        const dateA = new Date(a[sortField]);
        const dateB = new Date(b[sortField]);
        if (sortDirection === "asc") {
          return dateA - dateB;
        } else {
          return dateB - dateA;
        }
      } else {
        if (sortDirection === "asc") {
          return a[sortField].localeCompare(b[sortField]);
        } else {
          return b[sortField].localeCompare(a[sortField]);
        }
      }
    }
    return 0;
  });

  // Get the items for the current page
  const currentPageData = sortedData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Calculate the total number of pages
  const totalPages = Math.ceil(sortedData.length / itemsPerPage);

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="container notification-settings-page__container navbar-margin">
      <PageHeader
        title={t("notification_page_title")}
        helperTitle={t("notification_page_title")}
        helperBody={t("notification_page_helper")}
      />
      <div className="notification-settings-page__content">
        <ToggleButton
          title={t("notification_page_receive")}
          value={userEmailNotificationSetting}
          onSave={handleUpdateUserEmailNotificationSetting}
        />
        {/*    <NotificationSettings /> */}
        <NotificationTable
          currentPageData={currentPageData}
          notificationTableHeaders={notificationTableHeaders}
          handleSort={handleSort}
          sortField={sortField}
          sortDirection={sortDirection}
          handleNotificationInfoModalShow={handleNotificationInfoModalShow}
          handlePageChange={handlePageChange}
          currentPage={currentPage}
          totalPages={totalPages}
        />
        <NotificationModal
          showNotificationInfoModal={showNotificationInfoModal}
          handleNotificationInfoModalClose={handleNotificationInfoModalClose}
          notificationTableHeaders={notificationTableHeaders}
          notificationDetails={selectedNotification}
        />
      </div>
    </div>
  );
};

export default NotificationSettingsPage;
