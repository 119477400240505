import axios from "axios";
const backendUrl = process.env.REACT_APP_BACKEND_URL_FOR_FRONTEND;
const token = localStorage.getItem("token");

export const getCompanyNameByCompanyId = async (id) => {
  try {
    const company = await axios.get(
      `${backendUrl}/settings/company/name/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return company.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getAllCompanies = async () => {
  try {
    const companies = await axios.get(
      `${backendUrl}/settings/company/companies`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return companies.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getAllCompanyUsersByCompanyId = async (companyId) => {
  try {
    const users = await axios.get(
      `${backendUrl}/settings/company/users/${companyId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return users.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getCompanyDataByCompanyId = async (id) => {
  try {
    const company = await axios.get(
      `${backendUrl}/settings/company/company/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return company.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

// UPDATE COMPANY SETTINGS

export const updateCompanyDataByFieldByCompanyId = async (
  companyId,
  field,
  value
) => {
  try {
    await axios.patch(
      `${backendUrl}/settings/company/company/${companyId}/${field}`,
      { value },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } catch (error) {
    if (
      error.response &&
      error.response.data &&
      error.response.data.message ===
        "The value is the same as the current value"
    ) {
      throw new Error("update_company_data_error_same_values");
    } else {
      throw new Error(error.message);
    }
  }
};

export const updateCompanyNameInSettingsByCompanyId = async (
  id,
  companyName
) => {
  try {
    await axios.patch(
      `${backendUrl}/settings/company/name/${id}`,
      { companyName },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } catch (error) {
    throw new Error(error.message);
  }
};