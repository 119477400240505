/**
 * SettingsPage.jsx
 *
 * This component represents the settings page of the application.
 * The component renders a div with the class "container settings-page__container navbar-margin", which serves as a container for the entire page.
 * Inside this container, there is an h1 element with the class "page-title" that displays the title of the page ("Beállítások").
 * The rest of the page is contained in a div with the class "settings-page__content", which is divided into three sections:
 * - The first section, with the class "settings-page__userinfo", displays the user's profile picture and name. The profile picture is contained in a div with the class "settings-page__userinfo__image", and the name is contained in a div with the class "settings-page__userinfo__data".
 * - The second section, with the class "settings-page__base-list", contains a list of links to other settings pages (e.g., "Fiók kezelése", "Cég beállítások", "Értesítések és riasztások"). Each link is wrapped in a li element and uses the Link component from react-router-dom to navigate to the corresponding page.
 * - The third section, with the class "settings-page__more-list", contains a list of links to additional pages (e.g., "Súgó és támogatás", "Névjegy és jogi információk"). Each link is wrapped in a li element and uses the Link component from react-router-dom to navigate to the corresponding page.
 */

import React, { useContext, useEffect } from "react";
import UserContext from "../../context/UserContext";
import useLogout from "../../hooks/useLogout";
import { Link } from "react-router-dom";
import "./SettingsPage.scss";
import RedButton from "../Shared/RedButton/RedButton";
import { LogoutOutlined } from "../../helpers/icons/antDesignIcons";
import useTranslation from "../../hooks/useTranslation";
import PageHeader from "../Shared/PageHeader/PageHeader";
import USER_ROLES from "../../constants/userRoles";

const SettingsPage = () => {
  //  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const { user } = useContext(UserContext);
  const handleLogout = useLogout();
  const { t } = useTranslation("settingsPage");

  return (
    <div className="container settings-page__container navbar-margin">
      <PageHeader
        title={t("settings")}
        helperTitle={t("settings")}
        helperBody={t("settings_page_helper")}
      />
      <div className="settings-page__content">
        <section className="settings-page__userinfo">
          {/* <div className="settings-page__userinfo__image">
            <img src="https://via.placeholder.com/75" alt="User" />
          </div> */}
          <div className="settings-page__userinfo__data">
            <h2>
              {user.lastname} {user.firstname}
            </h2>
            <p>{user.companyname}</p>
          </div>
        </section>
        <section className="settings-page__base-list">
          <ul aria-label={t("general")}>
            <li>
              <Link to="/settings/user">{t("account_settings")}</Link>
            </li>
            {user.roles < USER_ROLES.COMPANYUSER && (
              <li>
                <Link to="/settings/company">{t("company_settings")}</Link>
              </li>
            )}
            <li>
              <Link to="/settings/notifications">
                {t("notifications_settings")}
              </Link>
            </li>
          </ul>
        </section>
        <section className="settings-page__more-list">
          <ul aria-label={t("more_settings")}>
            <li>
              <Link to="#">{t("help_and_support")}</Link>
            </li>
            <li>
              <Link to="#">{t("about_and_legal_information")}</Link>
            </li>
          </ul>
        </section>
      </div>
      <RedButton
        icon={<LogoutOutlined />}
        content={t("logout")}
        onClick={handleLogout}
      />
    </div>
  );
};

export default SettingsPage;
