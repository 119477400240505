import React, { useState } from "react";
import { useUserRole } from "../../../context/userRoleContext"
import { useNavigate } from "react-router-dom";
import { FormSelect, ListGroup, Modal } from "react-bootstrap";
import "./DashboardListMoreInfoModal.scss";
import useTranslation from "../../../hooks/useTranslation";

const DasboardListMoreInfoModal = ({
  show,
  handleClose,
  dashboardListItemData,
}) => {
  const navigate = useNavigate();
  const [unit, setUnit] = useState("Celsius");
  const userRole = useUserRole();
  const { t } = useTranslation("dashboardPage");

  const convertToFahrenheit = (celsius) => {
    return (celsius * 9) / 5 + 32;
  };

  if (!dashboardListItemData) {
    return null;
  }

/*   const goToLocationMoreInfo = (container) => {
    navigate(`/locations/${container.containers_id}`, {
      state: { container },
    });
  }; */

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton className="dashboard-moreinfo-modal-header">
        <Modal.Title className="dashboard-moreinfo-modal-header-title">
          {t("alertSentByDevice")}: {dashboardListItemData.probeid}{" "}
          <FormSelect
            aria-label="Unit select"
            value={unit}
            onChange={(e) => setUnit(e.target.value)}
            className="dashboard-moreinfo-modal-header-title-select"
          >
            <option value="Celsius">°C</option>
            <option value="Fahrenheit">°F</option>
          </FormSelect>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="dashboard-alert-moreinfo-modal-body">
        <ListGroup>
          <ListGroup.Item>
            {t("sensNum")}: {dashboardListItemData.sens_num}
          </ListGroup.Item>
          <ListGroup.Item
           /*  onClick={() => goToLocationMoreInfo(dashboardListItemData)} */
            className="dashboard-alert-moreinfo-modal-location"
          >
             {t("container")}: {dashboardListItemData.containername}
          </ListGroup.Item>
          {( userRole === "0" || userRole === "1") &&  (
          <ListGroup.Item>
            {t("site")}: {dashboardListItemData.sitename}
          </ListGroup.Item>
          )}
          <ListGroup.Item>
          {t("minimumValue")}:{" "}
            {unit === "Celsius"
              ? dashboardListItemData.min_value  + "°C"
              : convertToFahrenheit(dashboardListItemData.min_value).toFixed(2) +
              "°F"}
          </ListGroup.Item>
          <ListGroup.Item>
          {t("maximumValue")}:{" "}
            {unit === "Celsius"
              ? dashboardListItemData.max_value  + "°C"
              : convertToFahrenheit(dashboardListItemData.max_value).toFixed(2) +
              "°F"}
          </ListGroup.Item>
          <ListGroup.Item>
          {t("lastMeasuredValue")}:{" "}
            {unit === "Celsius"
              ? dashboardListItemData.sens_value + "°C"
              : convertToFahrenheit(dashboardListItemData.sens_value).toFixed(2) +
              "°F"}
          </ListGroup.Item>
          <ListGroup.Item>
            {t("measuredTime")}:{" "}
            {new Date(dashboardListItemData.sens_timestamp).toLocaleString()}
          </ListGroup.Item>
          {( userRole === "0" || userRole === "1") &&  (
            <ListGroup.Item>
              {t("user")}: {dashboardListItemData.user}
            </ListGroup.Item>
          )}
          {userRole === "0" && (
            <ListGroup.Item>
              {t("companyName")}: {dashboardListItemData.companyname}
            </ListGroup.Item>
          )}
        </ListGroup>
      </Modal.Body>
    </Modal>
  );
};

export default DasboardListMoreInfoModal;
