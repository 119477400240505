/**
 * CompanySettingsPage Component
 *
 * This component is responsible for rendering the company settings page.
 * It uses the `useSaveHandler` custom hook to handle the saving of the company name.
 * It also renders the `EditableSettingItem`, `UsersList`, and `LocationsList` components.
 *
 * The `EditableSettingItem` component is used to display and edit the company name.
 * The `UsersList` and `LocationsList` components are used to display lists of users and locations respectively.
 *
 * The data for the users and locations lists are currently dummy data (`usersListDummyData` and `locationsListDummyData`).
 * The headers for the users and locations tables (`userTableHeaders` and `locationTableHeaders`) are defined in the component.
 *
 * This component will be connected to the backend in the future to fetch and update real data.
 */

import React, { useContext, useEffect, useState } from "react";
import UserContext from "../../../context/UserContext";
/* import useSavehandler from "../../../hooks/useSaveHandler"; */
import "./CompanySettingsPage.scss";
import EditableSettingItem from "../Shared/EditableSettingItem/EditableSettingItem";
import {
  getAllCompanyUsers,
  /*   getAllLocations, */
} from "../../../api/companySettingsApi";
import {
  getAllCompanies,
  getAllCompanyUsersByCompanyId,
  getCompanyDataByCompanyId,
  updateCompanyDataByFieldByCompanyId,
} from "../../../api/settingsCompanyApi";
import {
  getAllSites,
  getAllSitesByCompanyId,
  addNewSiteByCompanyId,
} from "../../../api/locationApi";
import UsersList from "./CompanyUsers/UsersList";
import LocationsList from "./CompanyLocations/LocationsList";
import { toast } from "react-toastify";
import useTranslation from "../../../hooks/useTranslation";
import validateNewSiteData from "../../../helpers/validateNewSiteData";
import PageHeader from "../../Shared/PageHeader/PageHeader";

const CompanySettingsPage = () => {
  const { user } = useContext(UserContext);
  const { t } = useTranslation("settingsPage");
  const companyId = Number(user.company_id);
  const [userListData, setUserListData] = useState([]);
  const [locationListData, setLocationListData] = useState([]);
  // --- COMPANY DATA STATES ---
  const [companyData, setCompanyData] = useState({
    companyname: "",
    companycity: "",
    zip: "",
    street: "",
    housenr: "",
    taxnumber: "",
    phonenumber: "",
    licences: "",
  });
  const [companyList, setCompanyList] = useState([]);
  const [isUserSuperAdmin, setIsUserSuperAdmin] = useState(false);

  // Define the headers for the users table
  const userTableHeaders = {
    lastname: t("lastname"),
    firstname: t("firstname"),
    email:  t("email"),
    roles: t("role"),
/*     location: "Telephely", */
    emailnotification: t("settingsNotifications"),
    userMoreInfo: "",
  };

  // Define the headers for the locations table
  const locationTableHeaders = {
    sitename: t("siteName"),
    zip: t("zip"),
    sitecity: t("city"),
    sitestreet: t("street"),
    sitehousenr: t("houseNumber"),
    container_count: t("containerCount"),
    locationMoreInfo: "",
  };

  // Company data fetch
  const fetchCompanyData = async () => {
    try {
      const companyData = await getCompanyDataByCompanyId(user.company_id);
      setCompanyData(companyData);
    } catch (error) {
      console.error("Error fetching company data: ", error);
    }
  };

  useEffect(() => {
    fetchCompanyData();
  }, []);

  // Update the company data by field
  const updateCompanyDataByField = async (field, value) => {
    try {
      await updateCompanyDataByFieldByCompanyId(user.company_id, field, value);
      toast.success(t("update_company_data_success"));
      fetchCompanyData();
    } catch (error) {
      if (error.message === "update_company_data_error_same_values") {
        console.error("Error updating company data: ", error);
        toast.error(t("update_company_data_error_same_values"));
      } else {
        console.error("Error updating company data: ", error);
        toast.error(t("update_company_data_error"));
      }
    }
  };

  // Fetch the company list if the user is a super admin user
  useEffect(() => {
    if (user && user.roles === "0") {
      setIsUserSuperAdmin(true);
      getAllCompaniesForFiltering();
    } else {
      setIsUserSuperAdmin(false);
      getAllUsersInCompany(user.company_id);
    }
  }, [user]);

  useEffect(() => {
    if (isUserSuperAdmin) {
      getAllCompaniesForFiltering();
    }
  }, [isUserSuperAdmin]);

  const fetchUsersAndLocations = async () => {
    try {
      if (isUserSuperAdmin) {
        await getAllCompaniesForFiltering();
        const users = await getAllCompanyUsers();
        setUserListData(users);
        /* console.log("USERS AS SUPERADMIN", users); */
        const locations = await getAllSites();
        setLocationListData(locations);
      } else {
        const users = await getAllCompanyUsersByCompanyId(user.company_id);
        setUserListData(users);
        /*         console.log("USERS AS NO SUPERADMIN", users);
         */ const locations = await getAllSitesByCompanyId(user.company_id);
        setLocationListData(locations);
        /*         console.log("LOCATIONS AS NO SUPERADMIN", locations);
         */
      }
    } catch (error) {
      console.error("Error fetching users: ", error);
    }
  };

  useEffect(() => {
    fetchUsersAndLocations();
  }, [isUserSuperAdmin]);

  // Fetch all companies for filtering if the user is a super admin user
  const getAllCompaniesForFiltering = async () => {
    try {
      const companies = await getAllCompanies();
      setCompanyList(companies);
    } catch (error) {
      console.error("Error fetching companies: ", error);
    }
  };

  // Fetch all users in the company if the user is not a super admin user
  const getAllUsersInCompany = async () => {
    try {
      const users = await getAllCompanyUsersByCompanyId(user.company_id);
      setUserListData(users);
    } catch (error) {
      console.error("Error fetching users: ", error);
    }
  };

  // Fetch all sites in the company if the user is not a super admin user
  /*   const getAllSitesInCompany = async () => {
    try {
      const sites = await getAllSitesByCompanyId(user.company_id);
      setLocationListData(sites);
    } catch (error) {
      console.error("Error fetching sites: ", error);
    }
  }; */

  const handleCompanyFilterChange = async (companyid) => {
    const selectedCompanyId = companyid;

    if (selectedCompanyId === "all") {
      try {
        const companies = await getAllCompanies();
        setCompanyList(companies);

        // Fetch all users across all companies
        const allUsers = await getAllCompanyUsers();
        setUserListData(allUsers);
        /*         console.log("allUsers", allUsers);
         */
      } catch (error) {
        console.error("Error fetching all users: ", error);
      }
      return;
    }

    /*     console.log("userList", userListData);
     */
    // Filter the users by the selected company
    try {
      const users = await getAllCompanyUsersByCompanyId(selectedCompanyId);
      setUserListData(users);
    } catch (error) {
      console.error("Error fetching users for selected company: ", error);
    }
  };

  // Validate the field value
  const validateField = (field, value) => {
    let isValid = true;
    let errorMessage = "";

    // General validation logic
    if (value === "" || value === " ") {
      isValid = false;
      errorMessage = t("update_company_data_error_field_empty");
    }

    switch (field) {
      case "companyname":
        if (value.length < 3 || value.length > 100) {
          isValid = false;
          errorMessage = t("update_company_data_error_companyname");
        }
        break;
      case "zip":
        if (!/^\d{4,5}$/.test(value)) {
          isValid = false;
          errorMessage = t("update_company_data_error_zip");
        }
        break;
      case "companycity":
        if (value.length < 3 || value.length > 100) {
          isValid = false;
          errorMessage = t("update_company_data_error_city");
        }
        break;
      case "street":
        if (value.length < 3 || value.length > 100) {
          isValid = false;
          errorMessage = t("update_company_data_error_street");
        }
        break;
      case "housenr":
        if (value.length < 1 || value.length > 10) {
          isValid = false;
          errorMessage = t("update_company_data_error_housenumber");
        }
        break;
      case "taxnumber":
        if (value.length > 20) {
          isValid = false;
          errorMessage = t("update_company_data_error_taxnumber");
        }
        break;
      case "phonenumber":
        if (!/^\d{6,15}$/.test(value)) {
          isValid = false;
          errorMessage = t("update_company_data_error_phonenumber");
        }
        break;
      default:
        break;
    }

    return { isValid, errorMessage };
  };

  // Handle the save event for the editable setting item
  const handleSave = async (newValue, id) => {
    // Validate the field
    const { isValid, errorMessage } = validateField(id, newValue);

    if (!isValid) {
      toast.error(errorMessage);
      return;
    }

    // Update logic
    setCompanyData((prevData) => ({
      ...prevData,
      [id]: newValue,
    }));

    // Add logic to save the updated data to the server if needed
    try {
      await updateCompanyDataByField(id, newValue);
    } catch (error) {
      console.error("Error updating company data: ", error);
      toast.error(t("company_data_update_error"));
    }
  };

  // ------- SITES -------
  // Validate new site data

  // Handle add new site click
  const handleAddNewSiteClick = async (siteData) => {
    const errors = await validateNewSiteData(siteData, user, t);

    if (Object.keys(errors).length > 0) {
      toast.error(Object.values(errors).join(", "));
      return;
    }

    try {
      // Add new site to the database
      await addNewSiteByCompanyId(companyId, siteData);
      toast.success("Telephely sikeresen hozzáadva!");
      fetchUsersAndLocations();
      //close the modal
    } catch (error) {
      console.error("Error adding new site: ", error);
      toast.error("Hiba történt a telephely hozzáadásakor!");
    }
  };

  return (
    <div className="container company-settings-page__container navbar-margin">
      <PageHeader
        title={t("company_settings")}
        helperTitle={t("company_settings")}
        helperBody={t("company_settings_page_helper")}
      />
      <div className="user-settings-page__content">
        <EditableSettingItem
          id="companyname"
          title={t("company_name")}
          value={companyData.companyname}
          onSave={handleSave}
          type="text"
        />
        <EditableSettingItem
          id="zip"
          title="Irányítószám"
          value={companyData.zip}
          onSave={handleSave}
          type="text"
        />
        <EditableSettingItem
          id="companycity"
          title={t("company_city")}
          value={companyData.companycity}
          onSave={handleSave}
          type="text"
        />
        <EditableSettingItem
          id="street"
          title={t("company_street")}
          value={companyData.street}
          onSave={handleSave}
          type="text"
        />
        <EditableSettingItem
          id="housenr"
          title={t("company_house_number")}
          value={companyData.housenr}
          onSave={handleSave}
          type="text"
        />
        <EditableSettingItem
          id="taxnumber"
          title={t("company_tax_number")}
          value={companyData.taxnumber}
          onSave={handleSave}
          type="text"
        />
        <EditableSettingItem
          id="phonenumber"
          title={t("company_phone_number")}
          value={companyData.phonenumber}
          onSave={handleSave}
          type="text"
        />
        {/*        <EditableSettingItem
          id="licences"
          title="Licenszek"
          value={companyData.licences}
          onSave={handleSave}
          type="text"
        /> */}

        <UsersList
          tableHeaders={userTableHeaders}
          tableData={userListData}
          onChange={handleCompanyFilterChange}
          isUserSuperAdmin={isUserSuperAdmin}
          //Pass the company list to the UsersList component if the user is a super admin user
          {...(isUserSuperAdmin && { companyList })}
        />
        <LocationsList
          tableHeaders={locationTableHeaders}
          tableData={locationListData}
          onChange={handleCompanyFilterChange}
          fetchUsersAndLocations={fetchUsersAndLocations}
          isUserSuperAdmin={isUserSuperAdmin}
          handleAddNewSiteClick={handleAddNewSiteClick}
          //Pass the company list to the UsersList component if the user is a super admin user
          {...(isUserSuperAdmin && { companyList })}
        />
      </div>
    </div>
  );
};

export default CompanySettingsPage;
