/**
 * ThresholdAccordion Component
 *
 * This component renders two accordion items for managing threshold values for a list of sensors.
 *
 * Props:
 * - sensorsData: Data about the sensors.
 * - handleThresholdSaveClick: Function to handle saving the threshold values.
 * - handleThresholdAdditiveSaveClick: Function to handle saving the additive threshold values.
 * - onAdd: Function to handle adding a new threshold.
 *
 * State:
 * - minThreshold: Minimum threshold value.
 * - maxThreshold: Maximum threshold value.
 * - minAdditive: Minimum additive value for the threshold.
 * - maxAdditive: Maximum additive value for the threshold.
 * - isAccordion1Open: Boolean to manage the visibility of the first accordion item.
 * - isAccordion2Open: Boolean to manage the visibility of the second accordion item.
 *
 * The component allows users to input minimum and maximum threshold values directly or adjust them
 * additively. It provides a clear interface for managing these values across all sensors listed.
 */

import React, { useState } from "react";
import { Accordion, Button, Form } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import useTranslation from "../../../../hooks/useTranslation";

const ThresholdAccordion = ({
  sensorsData,
  handleThresholdSaveClick,
  handleThresholdAdditiveSaveClick,
  onAdd,
  sensorMinThreshold,
  sensorMaxThreshold,
}) => {
  const { t } = useTranslation("locationsPage");
  const [minThreshold, setMinThreshold] = useState("");
  const [maxThreshold, setMaxThreshold] = useState("");
  const [minAdditive, setMinAdditive] = useState(0);
  const [maxAdditive, setMaxAdditive] = useState(0);
  const [isAccordion1Open, setIsAccordion1Open] = useState(true);
  const [isAccordion2Open, setIsAccordion2Open] = useState(false);

  const handleAccordion1Click = () => {
    setIsAccordion1Open(!isAccordion1Open);
  };

  const handleAccordion2Click = () => {
    setIsAccordion2Open(!isAccordion2Open);
  };

  const handleFormDeleteClick = () => {
    setMinThreshold("");
    setMaxThreshold("");
    setMinAdditive(0);
    setMaxAdditive(0);
  };

  return (
    <div className="threshold-accordion-container">
      <ToastContainer />
      <Accordion className="threshold-accordion">
        <Accordion.Item eventKey="0" className="accordion-1">
          <Accordion.Header
            className={`accordion-1-header ${
              isAccordion1Open ? "accordion-1-open-header" : ""
            }`}
            onClick={handleAccordion1Click}
          >
            {t("thresholdvalues_modify_absolute_for_every_sensor")}
          </Accordion.Header>
          <Accordion.Body className="accordion-1-body">
            <Form>
              <Form.Group controlId="formMinThreshold">
                <Form.Label>{t("minValue")}</Form.Label>
                <Form.Control
                  type="number"
                  min={sensorMinThreshold}
                  max={sensorMaxThreshold}
                  value={minThreshold}
                  onChange={(e) => {
                    if (!isNaN(e.target.value) || e.target.value === "") {
                      setMinThreshold(e.target.value);
                    }
                  }}
                />
              </Form.Group>

              <Form.Group controlId="formMaxThreshold">
                <Form.Label>{t("maxValue")}</Form.Label>
                <Form.Control
                  type="number"
                  min={sensorMinThreshold}
                  max={sensorMaxThreshold}
                  value={maxThreshold}
                  onChange={(e) => {
                    if (!isNaN(e.target.value) || e.target.value === "") {
                      setMaxThreshold(e.target.value);
                    }
                  }}
                />
              </Form.Group>
              <div className="accordion-button-wrapper">
                <Button
                  variant="primary"
                  onClick={() =>
                    handleThresholdSaveClick(minThreshold, maxThreshold)
                  }
                  className="accordion-submit-button"
                >
                  {t("save")}
                </Button>
                <Button
                  onClick={handleFormDeleteClick}
                  className="accordion-submit-button accordion-delete-button"
                >
                  {t("delete")}
                </Button>
              </div>
            </Form>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header onClick={handleAccordion2Click}>
            {t("thresholdvalues_modify_additive_for_every_sensor")}
          </Accordion.Header>
          <Accordion.Body>
            <Form>
              <Form.Group controlId="formMinAdditive">
                <Form.Label>{t("modify_min_threshold_with")}:</Form.Label>
                <Form.Control
                  type="number"
                  value={minAdditive}
                  onChange={(e) => {
                    if (!isNaN(e.target.value) || e.target.value === "") {
                      setMinAdditive(e.target.value);
                    }
                  }}
                />
              </Form.Group>

              <Form.Group controlId="formMaxAdditive">
                <Form.Label>{t("modify_max_threshold_with")}:</Form.Label>
                <Form.Control
                  type="number"
                  value={maxAdditive}
                  onChange={(e) => {
                    if (!isNaN(e.target.value) || e.target.value === "") {
                      setMaxAdditive(e.target.value);
                    }
                  }}
                />
              </Form.Group>
              <div className="accordion-button-wrapper">
                <Button
                  variant="primary"
                  onClick={() =>
                    handleThresholdAdditiveSaveClick(minAdditive, maxAdditive)
                  }
                  className="accordion-submit-button"
                >
                  {t("save")}
                </Button>
                <Button
                  onClick={handleFormDeleteClick}
                  className="accordion-submit-button accordion-delete-button"
                >
                  {t("delete")}
                </Button>
              </div>
            </Form>
          </Accordion.Body>
        </Accordion.Item>{" "}
      </Accordion>
    </div>
  );
};

export default ThresholdAccordion;
