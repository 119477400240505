/**
 * This component renders a dashboard section with dynamic content. It utilizes the useState hook
 * to manage the state of the modal visibility and the useEffect hook to perform side effects,
 * such as fetching data when the component mounts. The component displays a title, a list of items
 * fetched from an API, and a modal that can be toggled by a button. The modal's visibility is
 * controlled by the component's state.
 *
 * Props:
 * - title: string - The title of the dashboard section.
 * - fetchData: function - A function to fetch data from an API. It should return a promise.
 *
 * State:
 * - items: array - The list of items fetched from the API.
 * - isModalOpen: boolean - A flag indicating whether the modal is open or closed.
 *
 * Effects:
 * - The component fetches data from the API when it mounts and updates the items state.
 *
 * Example usage:
 * <DashboardSection title="Example Title" fetchData={fetchDataFunction} />
 */

import React, { useEffect, useRef, useState } from "react";
import { Button, FormSelect } from "react-bootstrap";
import ListingTable from "../../Shared/ListingTable/ListingTable";
import { motion } from "framer-motion";
import "./DashboardSection.scss";
import useTranslation from "../../../hooks/useTranslation";

const DashboardSection = ({
  tableHeaders,
  title,
  items,
  linkTo,
  handleAcknowledge,
}) => {
  const [unit, setUnit] = useState("Celsius");
  const [height, setHeight] = useState("auto");
  const [rowLimit, setRowLimit] = useState(3);
  const [isSeeMoreButtonClicked, setIsSeeMoreButtonClicked] = useState(false);
  const sectionRef = useRef(null);
  const { t } = useTranslation("dashboardPage");

  const convertToFahrenheit = (celsius) => {
    return (celsius * 9) / 5 + 32;
  };

  useEffect(() => {
    if (sectionRef.current) {
      // Calculate the actual height of the content
      const contentHeight = `${sectionRef.current.scrollHeight}px`;

      // If isSeeMoreButtonClicked is true, set the height to the actual height of the content
      // Otherwise, set the height to '425px'
      setHeight(isSeeMoreButtonClicked ? contentHeight : "425px");
    }
  }, [isSeeMoreButtonClicked]);

  useEffect(() => {
    if (sectionRef.current) {
      // Calculate the actual height of the content
      const contentHeight = `${sectionRef.current.scrollHeight}px`;

      // If isSeeMoreButtonClicked is true, set the height to the actual height of the content
      // Otherwise, set the height to '425px'
      setHeight(isSeeMoreButtonClicked ? contentHeight : "425px");
    }
  }, [isSeeMoreButtonClicked]);

  useEffect(() => {
    // Set the initial height to the height of the content
    if (sectionRef.current) {
      setHeight(`${sectionRef.current.scrollHeight}px`);
    }
  }, []);

  return (
    <motion.div
      className="dashboard-section"
      animate={{ height: height }} // Use the actual height of the content
      transition={{ duration: 0.8, ease: "linear" }}
    >
      {title === (t("dashboard_table_title")) && (
        <div className="dashboard-section__header">
          <h2 className="dashboard-section__title">{title}</h2>
          {/*        <div
            className="dashboard-section__btn btn btn-primary"
            onClick={() => handleAcknowledge(items)}
          >
            Mindet elismer 
          </div> */}

          <div className="dashboard-section__selection__wrapper">
            <FormSelect
              className="dashboard-section__selection"
              value={unit}
              onChange={(e) => setUnit(e.target.value)}
            >
              <option value="Celsius">°C</option>
              <option value="Fahrenheit">°F</option>
            </FormSelect>
          </div>
        </div>
      )}
      {title !== t("dashboard_table_title") && (
        <h2 className="dashboard-section__title">{title}</h2>
      )}

      <ListingTable
        tableHeaders={tableHeaders}
        tableData={items}
        rowLimit={rowLimit}
        handleAcknowledge={handleAcknowledge}
        unit={unit}
        setUnit={setUnit}
        convertToFahrenheit={convertToFahrenheit}
      />
      <Button
        className="dashboard-section__btn btn btn-primary"
        onClick={(e) => {
          setRowLimit(isSeeMoreButtonClicked ? 3 : 999);
          setIsSeeMoreButtonClicked(!isSeeMoreButtonClicked);
        }}
      >
        {isSeeMoreButtonClicked === false ? t("seeAll") : t("seeLess")}
      </Button>
    </motion.div>
  );
};

export default DashboardSection;
