/**
 * SensorInfoModal Component
 *
 * This component is responsible for displaying a modal with detailed information about a sensor.
 * It shows the sensor's minimum and maximum threshold values, the last measured value (in Celsius or Fahrenheit),
 * and the last measurement time. Users can toggle between Celsius and Fahrenheit for the last measured value.
 *
 * Props:
 * - show: Boolean indicating if the modal should be shown.
 * - handleClose: Function to call when closing the modal.
 * - sensorData: Object containing sensor details such as ID, min/max threshold values, last measured value, and last measurement timestamp.
 *
 * State:
 * - unit: String indicating the current unit for temperature display ("Celsius" or "Fahrenheit").
 *
 * Dependencies:
 * - React: For creating the component and managing state.
 * - react-bootstrap: For the Modal, Button, FormSelect, and ListGroup components.
 */

import React, { useState } from "react";
import { Button, FormSelect, ListGroup, Modal } from "react-bootstrap";
import useTranslation from "../../../../../../hooks/useTranslation";

const SensorInfoModal = ({ show, handleClose, sensorData }) => {
  const [unit, setUnit] = useState("Celsius");
  const { t } = useTranslation("devicesPage");

  const convertToFahrenheit = (celsius) => {
    return (celsius * 9) / 5 + 32;
  };

  if (!sensorData) {
    return null;
  }

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>{t("sensNum")}: {sensorData.sens_num}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ListGroup>
          <ListGroup.Item>
          {t("minimumValue")}:{" "}
            {unit === "Celsius"
              ? sensorData.min_value
              : convertToFahrenheit(sensorData.min_value)}
          </ListGroup.Item>
          <ListGroup.Item>
          {t("maximumValue")}:{" "}
            {unit === "Celsius"
              ? sensorData.max_value
              : convertToFahrenheit(sensorData.max_value)}
          </ListGroup.Item>
          <ListGroup.Item>
          {t("lastMeasuredValue")}:{" "}
            {unit === "Celsius"
              ? sensorData.sens_value
              : convertToFahrenheit(sensorData.sens_value)}
            <FormSelect
              aria-label="Unit select"
              value={unit}
              onChange={(e) => setUnit(e.target.value)}
              style={{
                width: "75px",
                display: "inline-block",
                marginLeft: "10px",
              }}
            >
              <option value="Celsius">°C</option>
              <option value="Fahrenheit">°F</option>
            </FormSelect>
          </ListGroup.Item>
          <ListGroup.Item>
          {t("lastMeasuredTime")}:{" "}
            {new Date(sensorData.sens_timestamp).toLocaleString()}
          </ListGroup.Item>
        </ListGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          {t("cancel")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SensorInfoModal;
