import { useNavigate } from 'react-router-dom';

// Custom hook to handle device link click and navigate to device details page
export const useHandleDeviceLinkClick = () => {
  const navigate = useNavigate();

  const handleDeviceLinkClick = (device, e) => {
    e.preventDefault();
    navigate(`/devices/${device.probeid}`, { state: { device } });
  };

  return handleDeviceLinkClick;
};