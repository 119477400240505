/*
 *LocationList component
 *
 *This component will be connected to the backend in the future to fetch and update real data.
 * This component is responsible for rendering a list of locations and a button to add a new location.
 *
 * It uses a local state to manage the visibility of the AddNewLocationModal component.
 * The AddNewLocationModal component is used to add a new location.
 * The SettingsListingTable component is used to display the list of locations.
 *
 */

import React, { useState } from "react";
import { Button, FloatingLabel, FormSelect } from "react-bootstrap";
import { PlusCircleOutlined } from "../../../../helpers/icons/antDesignIcons";
import SettingsListingTable from "../../Shared/SettingsListingTable/SettingsListingTable";
import AddSite from "../../../LocationsPage/AddButtons/AddSite";
import "../CompanySettingsPage.scss";
import useTranslation from "../../../../hooks/useTranslation";

const LocationsList = ({
  tableHeaders,
  tableData,
  isUserSuperAdmin,
  fetchUsersAndLocations,
  handleAddNewSiteClick,
}) => {
  const { t } = useTranslation("locationsPage");
 
  return (
    <div className="company-settings-page__location-list">
      <div className="company-settings-page__list__header">
        <h2>{t("sites")}</h2>
        <AddSite handleAddNewSiteClick={handleAddNewSiteClick} />
        {/*        <AddNewLocationModal
          showNewLocationModal={showNewLocationModal}
          handleNewLocationModalClose={handleNewLocationModalClose}
          handleNewLocationModalSubmit={handleNewLocationModalSubmit}
        /> */}
      </div>
      {/*       {isUserSuperAdmin && (
        <div className="company-settings-page__list__company-select">
          <FloatingLabel controlId="floatingSelect" label="Cég kiválasztása">
            <FormSelect onChange={(e) => onChange(e.target.value)}>
              <option value="all">Összes cég</option>
              {companyList.map((company) => (
                <option key={company.companyid} value={company.companyid}>
                  {company.companyname}
                </option>
              ))}
            </FormSelect>
          </FloatingLabel>
        </div>
      )} */}
      <div className="company-settings-page__list__content">
        <SettingsListingTable
          tableHeaders={tableHeaders}
          tableData={tableData?.data || []}
          tableType="location"
          fetchUsersAndLocations={fetchUsersAndLocations}
          isUserSuperAdmin={isUserSuperAdmin}
        />
      </div>
    </div>
  );
};

export default LocationsList;
