/**
 * ConnectBaseToContainerButton Component
 * 
 * This component provides a button that opens a modal for managing the connection
 * between bases and containers. Users can select a base and a container to connect
 * them, or remove a base from a container. The component uses React hooks for state
 * management and side effects, and interacts with an API to perform the necessary
 * operations.
 * 
 * Props:
 * - baselist: Array of available bases.
 * - containerList: Array of available containers.
 * - baseListForDelete: Array of bases that can be removed from containers.
 * - companyId: The ID of the company.
 * - fetchBasesWithNoContainerAndProbeIdByCompanyId: Function to fetch bases with no container.
 * - fetchContainersWithNoBase: Function to fetch containers with no base.
 * - fetchBasesInContainers: Function to fetch bases in containers.
 * 
 * Key Features:
 * - Open a modal to manage base-container connections.
 * - Select a base and a container to connect them.
 * - Remove a base from a container.
 * - Display error messages if the operations fail.
 * - Use React hooks for state management and side effects.
 * 
 * Example usage:
 * <ConnectBaseToContainerButton 
 *   baselist={baselist} 
 *   containerList={containerList} 
 *   baseListForDelete={baseListForDelete} 
 *   companyId={companyId} 
 *   fetchBasesWithNoContainerAndProbeIdByCompanyId={fetchBasesWithNoContainerAndProbeIdByCompanyId} 
 *   fetchContainersWithNoBase={fetchContainersWithNoBase} 
 *   fetchBasesInContainers={fetchBasesInContainers} 
 * />
 */

import React, { useEffect, useState } from "react";
import { Button, FormSelect, Modal } from "react-bootstrap";
import {
  removeBaseFromContainerByBaseIdAndCompanyId,
  connectBaseToContainer,
} from "../../../api/baseApi";
import { AppstoreAddOutlined } from "../../../helpers/icons/antDesignIcons";
import { toast } from "react-toastify";
import "./ConnectBaseToContainerButton.scss";
import useTranslation from "../../../hooks/useTranslation";

const ConnectBaseToContainerButton = ({
  baselist,
  containerList,
  baseListForDelete,
  companyId,
  fetchBasesWithNoContainerAndProbeIdByCompanyId,
  fetchContainersWithNoBase,
  fetchBasesInContainers,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [bases, setBases] = useState([]);
  const [selectedBase, setSelectedBase] = useState(null);
  const [selectedBaseForRemove, setSelectedBaseForRemove] = useState(null);
  const [containers, setContainers] = useState([]);
  const [selectedContainer, setSelectedContainer] = useState(null);
  const [refreshKey, setRefreshKey] = useState(0); // State to force re-render
  const { t } = useTranslation("devicesPage");

  useEffect(() => {
    setBases(Array.isArray(baselist) ? baselist : []);
    setContainers(Array.isArray(containerList) ? containerList : []);
  }, [baselist, containerList, baseListForDelete]);

  const refreshLists = async () => {
    try {
      const updatedBaseList =
        await fetchBasesWithNoContainerAndProbeIdByCompanyId(companyId);
      setBases(updatedBaseList);
      const updatedContainerList = await fetchContainersWithNoBase(companyId);
      setContainers(updatedContainerList)
      const updatedBaseListForDelete = await fetchBasesInContainers(companyId);
      setSelectedBaseForRemove(updatedBaseListForDelete);
    } catch (error) {
      console.error("Error fetching bases: ", error);
    }
  };

  const handleConnectBaseToContainerClick = async (baseId, containerId) => {
    if (!baseId || !containerId) {
      toast.error(t("select_base_and_container"));
      return;
    }

    // connect base to container
    try {
      await connectBaseToContainer(
        baseId,
        containerId,
        companyId
      );
      toast.success(t("base_connected_successfully"));
       refreshLists();
    } catch (error) {
      console.error("Error connecting base to container: ", error);
      toast.error(t("base_connected_error"));
    }
  };

  const handleDeleteBaseFromContainerClick = async (baseId, companyId) => {
    if (!baseId) {
      toast.error(t("select_base_to_remove"));
      return;
    }

    // remove base from container
    try {
      await removeBaseFromContainerByBaseIdAndCompanyId(baseId, companyId);
      toast.success(t("base_removed_successfully"));
      setSelectedBaseForRemove(""); // Reset the selection to the default option
      setRefreshKey(prevKey => prevKey + 1); // Increment to force re-render
      await refreshLists();
    } catch (error) {
      console.error("Error connecting base to container: ", error);
      toast.error(t("base_removed_error"));
    }
  };

  return (
    <div className="connect-device-container__btn__wrapper">
      <Button
        color="primary"
        onClick={() => setModalIsOpen(true)}
        className="connect-device-container__btn"
      >
        <AppstoreAddOutlined /> {t("base_manager")}
      </Button>
      <Modal centered show={modalIsOpen} onHide={() => setModalIsOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t("base_manager")}</Modal.Title>
        </Modal.Header>
        <div className="modal-body">
          <div className="base-connect-container">
            <h5>{t("connect_base_to_container")}</h5>
            <div className="form-group">
              {(bases || []).length === 0 && (
                <option value="">{t("no_available_bases")}</option>
              )}
              {(bases || []).length > 0 && (
                <>
                  <label htmlFor="base">{t("base")}</label>

                  <FormSelect
                    className="form-control"
                    id="base"
                    onChange={(e) => setSelectedBase(e.target.value)}
                  >
                    <option value="">{t("select_available_base")}</option>
                    {bases.map((base) => (
                      <option key={base.baseid} value={base.baseid}>
                        {base.baseid}
                      </option>
                    ))}
                  </FormSelect>
                </>
              )}
            </div>
            <div className="form-group">
              {(containers || []).length === 0 && (
                <option value="">{t("no_available_containers")}</option>
              )}
              {(containers || []).length > 0 && (
                <>
                  <label htmlFor="container">{t("container")}</label>
                  <FormSelect
                    className="form-control"
                    id="container"
                    onChange={(e) => setSelectedContainer(e.target.value)}
                  >
                    <option value="">{t("select_available_container")}</option>
                    {containers.map((container) => (
                      <option key={container._id} value={container._id}>
                        {container.containername}
                      </option>
                    ))}
                  </FormSelect>
                </>
              )}
            </div>
            <Button
              color="primary"
              onClick={() => {
                console.log(selectedBase, selectedContainer);
                handleConnectBaseToContainerClick(
                  selectedBase,
                  selectedContainer
                );
                /*       setModalIsOpen(!modalIsOpen); */
              }}
            >
              {t("connect")}
            </Button>
          </div>
          {/* bázis raktárhoz rendelésének törlése */}
          <div className="base-delete-container">
            <h5>{t("remove_base_from_container")}</h5>
            <div className="form-group">
              {(baseListForDelete || []).length === 0 && (
                <option value="">{t("no_available_bases_to_remove")}</option>
              )}
              {(baseListForDelete || []).length > 0 && (
                <>
                  <FormSelect
                   key={refreshKey}
                    className="form-control"
                    id="base-delete"
                    onChange={(e) => setSelectedBaseForRemove(e.target.value)}
                  >
                    <option value="">{t("select_available_base_to_remove")}</option>
                    {baseListForDelete.map((base, index) => (
                      <option key={index} value={base.baseid}>
                        {base.baseid} - {base.containername}
                      </option>
                    ))}
                  </FormSelect>
                  <Button
                    variant="danger"
                    onClick={() =>
                      handleDeleteBaseFromContainerClick(
                        selectedBaseForRemove,
                        companyId
                      )
                    }
                  >
                    {t("delete")}
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>

        <div className="modal-footer">
          <Button
            variant="default"
            onClick={() => setModalIsOpen(!modalIsOpen)}
            className="modal-close-btn"
          >
           {t("cancel")}
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default ConnectBaseToContainerButton;
