/**
 * SettingsListingTable Component
 *
 * This component renders a table for displaying settings data, such as user or location information.
 * It supports sorting, displaying modals for detailed information, and editing entries. The component
 * uses React Bootstrap for styling and modals, and Ant Design icons for visual elements.
 *
 * Props:
 * - tableHeaders: An object representing the headers of the table.
 * - tableData: An array of data objects to be displayed in the table.
 * - tableType: A string indicating the type of table ("location" or "user").
 * - fetchUsersAndLocations: A function to fetch users and locations data.
 * - isUserSuperAdmin: A boolean indicating if the current user is a super admin.
 *
 * Example usage:
 * <SettingsListingTable
 *   tableHeaders={tableHeaders}
 *   tableData={tableData}
 *   tableType="user"
 *   fetchUsersAndLocations={fetchUsersAndLocations}
 *   isUserSuperAdmin={true}
 * />
 */

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import { Modal } from "react-bootstrap";
import {
  EditOutlined,
  InfoCircleOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined,
} from "../../../../helpers/icons/antDesignIcons";
import "./SettingsListingTable.scss";
import useTranslation from "../../../../hooks/useTranslation";

const SettingsListingTable = ({ tableHeaders, tableData, tableType }) => {
  const navigate = useNavigate();
  const { t } = useTranslation("settingsPage");
  // User info modal state
  const [showUserInfoModal, setShowUserInfoModal] = useState(false);
  const [modalUserInfo, setModalUserInfo] = useState("");
  const [selectedLocation, setSelectedLocation] = useState(null);
  // Location info modal state
  const [showLocationInfoModal, setShowLocationInfoModal] = useState(false);
  const [modalLocationInfo, setModalLocationInfo] = useState("");
  const [showNotificationInfoModal, setShowNotificationInfoModal] =
    useState(false);
  // Sort state
  const [sortField, setSortField] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);

  const roleMapping = {
    0: t("SUPER_ADMIN"),
    1: t("COMPANY_ADMIN"),
    2: t("COMPANY_LOCATION_LEADER"),
    /*     3: "COMPANY_LOCATION_LEADER", */
    4: t("COMPANY_USER"),
  };

  // Handle user info modal
  const handleUserInfoModalClose = () => setShowUserInfoModal(false);
  const handleUserInfoModalShow = (userInfo) => {
    setModalUserInfo(userInfo);
    setShowUserInfoModal(true);
  };

  // Handle location info modal
  const handleLocationInfoModalClose = () => setShowLocationInfoModal(false);
  const handleLocationInfoModalShow = (locationInfo) => {
    console.log("Modal show triggered");
    console.log("Location Info:", locationInfo);
    console.log("TABLE TYPE", tableType);

    setModalLocationInfo(locationInfo);
    setShowLocationInfoModal(true);
  };

  console.log(showLocationInfoModal);
  console.log(modalLocationInfo);

  // Handle notification info modal
  const handleNotificationInfoModalClose = () =>
    setShowNotificationInfoModal(false);
  const handleNotificationInfoModalShow = () =>
    setShowNotificationInfoModal(true);

  const handleEditUser = (userInfo) => {
    navigate(`/settings/company/edit-user/${userInfo.id}`, {
      state: { modalUserInfo: userInfo },
    });
  };

  const handleEditLocation = (location) => {
    setSelectedLocation(location);
    console.log("SELECTED LOCATION", location);
    navigate(`/settings/company/sites/edit-location/${location._id}`);
  };

  const handleSort = (field) => {
    let direction = "asc";
    if (sortField === field && sortDirection === "asc") {
      direction = "desc";
    }
    setSortField(field);
    setSortDirection(direction);
  };

  // Sort the data
  const sortedData = Array.isArray(tableData)
    ? [...tableData].sort((a, b) => {
        // Existing sorting logic for other table types
        if (sortField) {
          if (sortDirection === "asc") {
            if (a[sortField] === b[sortField]) {
              return a.firstname.localeCompare(b.firstname);
            }
            return a[sortField].localeCompare(b[sortField]);
          } else {
            if (a[sortField] === b[sortField]) {
              return b.firstname.localeCompare(a.firstname);
            }
            return b[sortField].localeCompare(a[sortField]);
          }
        }
        return 0;
      })
    : [];

  console.log("tableType is: ", tableType);

  return (
    <>
      {/* if the tabletype is location the display this */}
      {tableType === "location1" || tableType === "locations2" ? (
        <div className="settings-listing-table table-wrapper">
          <Table>
            <thead>
              <tr className="table-header-tr">
                {Object.keys(tableHeaders).map((key, index) => (
                  <th
                    key={index}
                    /*  className={`table-header__item ${key}`} */
                    id={`csl-${key}-header`}
                  >
                    <p className="table-header__title">{tableHeaders[key]}</p>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {(!tableData || tableData.length === 0) && (
                <tr>
                  <td
                    colSpan={Object.keys(tableHeaders).length}
                    className="table-data"
                  >
                    {t("noDataToDisplay")}
                  </td>
                </tr>
              )}
              {tableData &&
                tableData.map((data, index) => (
                  <tr key={index}>
                    {Object.keys(tableHeaders).map((key, index) => (
                      <td
                        key={index}
                        /*  className={`table-data ${key}`} */
                        id={`csl-${key}-data`}
                      >
                        <div className="ellipsis">
                          {key === "locationMoreInfo" ? (
                            // Only render the button if data is valid
                            <Button
                              variant="link"
                              onClick={() => handleLocationInfoModalShow(data)}
                            >
                              <InfoCircleOutlined />
                            </Button>
                          ) : key === "sitecity" ? (
                            // Check if sitecity exists and is non-empty, otherwise display fallback message
                            data?.sitecity && data.sitecity.length > 0 ? (
                              `${data.sitecity}`
                            ) : (
                              `${t("noDataToDisplay")}`
                            )
                          ) : data && data[key] ? (
                            // General case for other keys in data
                            data[key]
                          ) : (
                            // Fallback for missing or empty data
                            t("noData")
                          )}
                        </div>
                      </td>
                    ))}
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
      ) : (
        // If the tabletype is user, display this
        // Display the table
        <div className="settings-listing-table table-wrapper">
          <Table>
            <thead>
              <tr className="table-header-tr">
                {Object.keys(tableHeaders).map((key, index) => (
                  <th key={index} className={`table-header__item ${key}`}>
                    <p className="table-header__title">{tableHeaders[key]}</p>
                    {/* If the key is "name" or "location", display the sort button */}
                    {key === "lastname" ||
                    key === "firstname" ||
                    key === "location" ||
                    key === "sentTime" ? (
                      <Button
                        variant="link"
                        className="table-header-sort__btn"
                        onClick={() => handleSort(key)}
                      >
                        {sortField === key && sortDirection === "asc" ? (
                          <SortDescendingOutlined />
                        ) : (
                          <SortAscendingOutlined />
                        )}
                      </Button>
                    ) : null}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {
                /* Display no data if the sortedData length is 0*/
                sortedData.length === 0 && (
                  <tr>
                    <td
                      colSpan={Object.keys(tableHeaders).length}
                      className="table-data"
                    >
                      Nincs megjeleníthető adat.
                    </td>
                  </tr>
                )
              }
              {sortedData.map((data, index) => (
                <tr key={index}>
                  {Object.keys(tableHeaders).map((key, index) => (
                    <td key={index} className={`table-data ${key}`}>
                      <div className="ellipsis">
                        {/* If the key is "moreInfo", display the edit button */}
                        {key === "userMoreInfo" ? (
                          <Button
                            variant="link"
                            onClick={() => {
                              console.log("USERS TABLE LOCATION INFO USES");
                              handleUserInfoModalShow(data);
                            }}
                          >
                            <InfoCircleOutlined />
                          </Button>
                        ) : key === "locationMoreInfo" ? (
                          <Button
                            variant="link"
                            onClick={() => {
                              console.log("USERS TABLE LOCATION INFO USES");
                              handleLocationInfoModalShow(data);
                            }}
                          >
                            <InfoCircleOutlined />
                          </Button>
                        ) : key === "notificationMoreInfo" ? (
                          <Button
                            variant="link"
                            onClick={handleNotificationInfoModalShow}
                          >
                            <InfoCircleOutlined />
                          </Button>
                        ) : key === "emailnotification" ? (
                          data[key] ? (
                            t("on")
                          ) : (
                            t("off")
                          )
                        ) : key === "roles" ? (
                          roleMapping[data[key]]
                        ) : (
                          data[key]
                        )}
                      </div>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
    
          {/* ----------- USER MORE INFO MODAL ----------- */}
          <Modal show={showUserInfoModal} onHide={handleUserInfoModalClose}>
            <Modal.Header closeButton>
              <Modal.Title>{`${modalUserInfo.lastname} ${modalUserInfo.firstname}`}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                {t("email")}: {modalUserInfo.email}
              </p>
              <p>
                {t("role")}: {roleMapping[modalUserInfo.roles]}
              </p>
              {/* <p>Telephely: {modalUserInfo.location}</p> */}
              <p>
                {t("settingsNotifications")}:{" "}
                {modalUserInfo.emailnotification ? t("on") : t("off")}
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                onClick={() => modalUserInfo && handleEditUser(modalUserInfo)}
              >
                <EditOutlined /> {t("edit")}
              </Button>
              <Button variant="secondary" onClick={handleUserInfoModalClose}>
                {t("cancel")}
              </Button>
            </Modal.Footer>
          </Modal>
          {/* ----------- LOCATION MORE INFO MODAL ----------- */}
          <Modal
            show={showLocationInfoModal}
            onHide={handleLocationInfoModalClose}
            className="csl-modal"
            style={{
              display: showLocationInfoModal ? "block !important" : "none",
              zIndex: 9999,
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>{modalLocationInfo.sitename}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                {t("address")}: {modalLocationInfo.zip}{" "}
                {modalLocationInfo.sitecity}, {modalLocationInfo.sitestreet}{" "}
                {modalLocationInfo.sitehousenr}
              </p>
              <p>
                {t("containerCount")}: {modalLocationInfo.container_count}
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                onClick={() => handleEditLocation(modalLocationInfo)}
              >
                <EditOutlined /> {t("edit")}
              </Button>
              <Button
                variant="secondary"
                onClick={handleLocationInfoModalClose}
              >
                {t("cancel")}
              </Button>
            </Modal.Footer>
          </Modal>
          {/* ----------- NOTIFICATION MORE INFO MODAL ----------- */}
          {/* <Modal
            show={showNotificationInfoModal}
            onHide={handleNotificationInfoModalClose}
          >
            <Modal.Header closeButton>
              <Modal.Title>Értesítés részletei</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                <strong>Cím:</strong> {notificationsDummyDataHun[0].title}
              </p>
              <p>
                <strong>Üzenet:</strong> {notificationsDummyDataHun[0].message}
              </p>
              <p>
                <strong>Felhasználó:</strong>{" "}
                {notificationsDummyDataHun[0].receivedUser}
              </p>
              <p>
                <strong>Időpont:</strong>{" "}
                {notificationsDummyDataHun[0].sentTime}
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={handleNotificationInfoModalClose}
              >
                {t("close")}
              </Button>
            </Modal.Footer>
          </Modal> */}
        </div>
      )}
    </>
  );
};

export default SettingsListingTable;
