/**
 * The ReportsPage component allows users to generate reports based on data stored in the database.
 * It enables users to select sites, containers, devices, and sensors to include in the report,
 * choose a time range, and then download the report as a ZIP file containing CSV files for each selected sensor.
 *
 * The component uses React hooks for state management and side effects, and interacts with an API
 * to fetch the necessary data. It also provides UI elements for user interaction, such as selection
 * menus and buttons, and displays helper text for guidance.
 *
 * Key Features:
 * - Fetch and display sites, containers, devices, and sensors based on the user's company and selections.
 * - Allow selection of time range for the report.
 * - Generate a ZIP file containing CSV files for each selected sensor.
 * - Display helper text and error messages to guide and inform the user.
 * - Utilize React hooks for state management and side effects.
 * - Interact with an API to fetch necessary data.
 * - Provide UI elements for user interaction, including selection menus and buttons.
 */

import React, { useContext, useEffect, useState } from "react";
import UserContext from "../../context/UserContext";
import useTranslation from "../../hooks/useTranslation";
import moment from "moment";
import {
  Button,
  Form,
  FormSelect,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";
import { toast } from "react-toastify";
import {
  getSiteNameBySiteId,
  getContainerNameByContainerId,
  getAllSitesByCompanyId,
  getAllContainersBySiteId,
  getAllDeviceDataByContainerId,
} from "../../api/locationApi";
import {
  getAllSensorsByDeviceId,
  getAllSensorsByContainerId,
} from "../../api/sensorApi";
import {
  getMeasurementReportsForSelectedDevicesForTheLast24hByCompanyId,
  getMeasurementReportsForSelectedDevicesForTheLastWeekByCompanyId,
  getMeasurementReportsForSelectedDevicesForTheLastMonthByCompanyId,
  getMeasurementReportsForSelectedDevicesForTheLastQuarterByCompanyId,
  getMeasurementReportsForSelectedDevicesForTheLastHalfYearByCompanyId,
  getMeasurementReportsForSelectedDevicesForTheLastYearByCompanyId,
  getMeasurementReportsForSelectedDevicesForCustomDateRangeByCompanyId,
} from "../../api/reportApi";
import { getCompanyNameByCompanyId } from "../../api/settingsCompanyApi";
import { sanitizeString } from "../../utils/stringUtils";
import CustomTimeRangePicker from "../Shared/CustomTimeRangePicker/CustomTimeRangePicker";
import TimeFrameNotification from "../Shared/TimeFrameNotification/TimeFrameNotification";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "../../helpers/icons/antDesignIcons";
import "./ReportsPage.scss";
import PageHeader from "../Shared/PageHeader/PageHeader";
import USER_ROLES from "../../constants/userRoles";

const ReportsPage = () => {
  const { user } = useContext(UserContext);
  const companyId = user.company_id;
  const userRole = user.role; //if superadmin see another filter for companies
  //translation related variables
  // Use the custom hook to load translations
  const { t } = useTranslation("reportsPage");

  // Define state variables for sites, containers, devices, sensors, and selected items
  const [sites, setSites] = useState([]);
  const [containers, setContainers] = useState([]);
  const [devices, setDevices] = useState([]);
  const [sensors, setSensors] = useState([]);
  const [sensorsInSelectedContainer, setSensorsInSelectedContainer] = useState(
    []
  );
  const [timeRange, setTimeRange] = useState("");

  // date picker related variables
  const [showDatePicker, setShowDatePicker] = useState(false);
  // Define state variables for start and end dates
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [selectedSites, setSelectedSites] = useState([]);
  const [selectedContainers, setSelectedContainers] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState([]);
  const [selectedSensors, setSelectedSensors] = useState(new Set());

  const [showOverlay, setShowOverlay] = useState(false);

  //Helper text for the page
  const helperText = t("report_page_helper")
    .split("\n")
    .map((line, index) => <p key={index}>{line}</p>);

  useEffect(() => {
    const fetchSites = async () => {
      try {
        const sitesData = await getAllSitesByCompanyId(companyId);
        setSites(sitesData.data);
      } catch (error) {
        console.error("Error fetching sites: ", error);
      }
    };
    fetchSites();
  }, []);

  const fetchContainers = async () => {
    try {
      // Initialize an empty array to hold all containers
      let allContainers = [];

      // Fetch containers for each selected site
      for (let siteId of selectedSites) {
        const containersData = await getAllContainersBySiteId(siteId);
        if (containersData && Array.isArray(containersData.data)) {
          // Check if containersData.data exists and is an array
          allContainers = [...allContainers, ...containersData.data]; // Use containersData.data
        } else {
          console.warn(
            "Received non-iterable containersData for site ID: ",
            siteId,
            "; containersData: ",
            containersData
          );
          // Optionally handle the non-array containersData here
        }
      }
      setContainers(allContainers);
    } catch (error) {
      console.error("Error fetching containers: ", error);
    }
  };

  const fetchDevices = async () => {
    try {
      let allDevices = [];
      for (let containerId of selectedContainers) {
        const devicesData = await getAllDeviceDataByContainerId(containerId);
        // Adjusted check: Directly check if devicesData is an array
        if (Array.isArray(devicesData)) {
          allDevices = [...allDevices, ...devicesData];
        } else {
          console.warn(
            "Received non-iterable devicesData for container ID: ",
            containerId,
            "; devicesData: ",
            devicesData
          );
        }
      }
      setDevices(allDevices);
    } catch (error) {
      console.error("Error fetching devices: ", error);
    }
  };

  const fetchSensors = async () => {
    try {
      // Initialize an empty array to hold all sensors
      let allSensors = [];

      // Fetch sensors for clicked device
      const sensorsData = await getAllSensorsByDeviceId(selectedDevice);
      if (sensorsData && Array.isArray(sensorsData)) {
        // Check if sensorsData.data exists and is an array
        allSensors = [...allSensors, ...sensorsData];
        setSensors(allSensors);
      } /* else {
        console.warn(
          "Received non-iterable sensorsData for device ID: ",
          selectedDevice,
          "; sensorsData: ",
          sensorsData
        );
      } */
    } catch (error) {
      console.error("Error fetching sensors: ", error);
      toast.error(t("sensorQueryError"));
    }
  };

  const fetchSensorsInSelectedContainer = async () => {
    try {
      // Initialize an empty array to hold all sensors
      let allSensors = [];

      // Fetch sensors for selected container
      const sensorsData = await getAllSensorsByContainerId(selectedContainers);
      if (sensorsData && Array.isArray(sensorsData)) {
        // Check if sensorsData.data exists and is an array
        allSensors = [...allSensors, ...sensorsData];
        setSensorsInSelectedContainer(allSensors);
      } /*  else {
        console.warn(
          "Received non-iterable sensorsData for container ID: ",
          selectedContainers,
          "; sensorsData: ",
          sensorsData
        );
      } */
    } catch (error) {
      console.error("Error fetching sensors: ", error);
      toast.error(t("sensorQueryError"));
    }
  };

  useEffect(() => {
    // Only fetch containers if at least one site is selected
    if (selectedSites.length > 0) {
      fetchContainers();
    }
  }, [selectedSites]);

  useEffect(() => {
    // Only fetch devices if at least one container is selected
    if (selectedContainers.length > 0) {
      fetchDevices();
      fetchSensorsInSelectedContainer();
    }
  }, [selectedContainers]);

  useEffect(() => {
    // Only fetch sensors if a device is selected
    if (selectedDevice) {
      fetchSensors();
    }
  }, [selectedDevice, selectedContainers]);

  useEffect(() => {
    if (timeRange === "custom") {
      setShowDatePicker(true);
    } else {
      setShowDatePicker(false);
    }
  }, [timeRange]);

  const handleSiteSelectChange = (event) => {
    const selected = Array.from(
      event.target.selectedOptions,
      (option) => option.value
    );
    setSelectedSites(selected);
  };

  const handleContainerSelectChange = (event) => {
    if (!event || !event.target) {
      console.error("Event or event target is undefined");
      return;
    }

    const selected = Array.from(
      event.target.selectedOptions,
      (option) => option.value
    );

    setSelectedContainers(selected);
  };

  const handleDeviceClick = (device) => {
    setSelectedDevice(device.probeid);
    setShowOverlay(true);
  };

  const handleSensorClick = (sensorId, probeId) => {
    setSelectedSensors((prevSelectedSensors) => {
      const updatedSelectedSensors = new Set(prevSelectedSensors);
      const sensorObj = { sensorId, probeId };

      // Check if the sensorObj is already in the set
      const exists = [...updatedSelectedSensors].some(
        (sensor) => sensor.sensorId === sensorId && sensor.probeId === probeId
      );

      if (exists) {
        // Remove the sensorObj from the set if it exists
        updatedSelectedSensors.forEach((sensor) => {
          if (sensor.sensorId === sensorId && sensor.probeId === probeId) {
            updatedSelectedSensors.delete(sensor);
          }
        });
      } else {
        // Add the sensorObj to the set if it doesn't exist
        updatedSelectedSensors.add(sensorObj);
      }

      return updatedSelectedSensors;
    });

    /*       if (updatedSelectedSensors.has({ sensorId, probeId })) {
        updatedSelectedSensors.delete({ sensorId, probeId });
      } else {
        updatedSelectedSensors.add({ sensorId, probeId });
      }
      return updatedSelectedSensors;
    }); */
  };

  const handleSelectAll = (device = null) => {
    const newSelectedSensors = new Set(selectedSensors);
    if (selectedContainers.length === 0) {
      toast.error(t("noSelectedContainer"));
      return;
    }

    if (device && device._id) {
      // Select all sensors for a specific device within the selected container
      const deviceSensors = sensors.filter(
        (sensor) => sensor.probeid === device.probeid && sensor._id
      );
      deviceSensors.forEach((sensor) => {
        newSelectedSensors.add({
          sensorId: sensor._id,
          probeId: device.probeid,
        });
      });
      toast.success(t("selectedAllSensorsInDevice"));
    } else {
      // Select all sensors in all devices within the selected container
      sensorsInSelectedContainer.forEach((sensor) => {
        newSelectedSensors.add({
          sensorId: sensor.sensor_id,
          probeId: sensor.probeid,
        });
      });

      // Show toast notification
      toast.success(t("selectedAllSensor"));
    }
    setSelectedSensors(newSelectedSensors);
  };

  const handleDeselectAll = (device = null) => {
    if (selectedSensors.size === 0) {
      toast.error(t("noSelectedSensor"));
      return;
    }

    if (device && device._id) {
      // Deselect all sensors for a specific device
      const remainingSensors = new Set(
        [...selectedSensors].filter(
          (selectedSensor) =>
            !sensors.some(
              (sensor) =>
                sensor._id === selectedSensor.sensorId &&
                sensor.probeid === device.probeid
            )
        )
      );
      setSelectedSensors(remainingSensors);
      toast.success(t("unselectedAllSensorsInDevice"));
    } else {
      // Deselect all sensors in all devices
      setSelectedSensors(new Set());
      toast.success(t("unselectedAllSensor"));
    }
  };

  // Callback functions to update date state in parent
  const handleDateChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  };

  console.log("Selected sensors:", selectedSensors);
  console.log(user.token);
  // Handle report submission
  const handleReportSubmitClicked = async (event) => {
    event.preventDefault();
    try {
      if (timeRange === "") {
        toast.error(t("noSelectedTimeFrame"));
        return;
      }

      if (selectedSensors.size === 0) {
        toast.error(t("noSelectedSensor"));
        return;
      }

      let errorMessage = null;

      // Get company name for the report
      const rawCompanyName = await getCompanyNameByCompanyId(companyId);
      const companyName = sanitizeString(rawCompanyName.companyName);

      // Get site name for the report
      const rawSiteName = await getSiteNameBySiteId(Number(selectedSites[0]));
      const siteName = sanitizeString(rawSiteName);

      // Get container name for the report
      const rawContainerName = await getContainerNameByContainerId(
        Number(selectedContainers[0])
      );
      const containerName = sanitizeString(rawContainerName);

      // Define date range based on selected time frame
      let dateFrom;
      let dateTo = moment().format("YYYY-MM-DD"); // Current date

      const language = localStorage.getItem("language");

      switch (timeRange) {
        case "last24h":
          dateFrom = moment().subtract(1, "days").format("YYYY-MM-DD");
          break;
        case "lastweek":
          dateFrom = moment().subtract(1, "weeks").format("YYYY-MM-DD");
          break;
        case "lastmonth":
          dateFrom = moment().subtract(1, "months").format("YYYY-MM-DD");
          break;
        case "lastquarter":
          dateFrom = moment().subtract(3, "months").format("YYYY-MM-DD");
          break;
        case "lasthalfyear":
          dateFrom = moment().subtract(6, "months").format("YYYY-MM-DD");
          break;
        case "lastyear":
          dateFrom = moment().subtract(1, "years").format("YYYY-MM-DD");
          break;
        case "custom":
          if (!startDate || !endDate) {
            toast.error(t("noSelectedStartOrEndDate"));
            return;
          }
          dateFrom = moment(startDate).format("YYYY-MM-DD");
          dateTo = moment(endDate).format("YYYY-MM-DD");
          break;
        default:
          toast.error(t("invalidTimeRange"));
          return;
      }

      // Convert selected sensors to an array of sensor IDs -- and probe IDs
      const sensorIds = Array.from(selectedSensors).map(
        (sensor) => sensor.sensorId
      );

      /*  const probeIds = Array.from(selectedSensors).map(
        (sensor) => sensor.probeId
      ); */

      if (timeRange === "last24h") {
        errorMessage =
          await getMeasurementReportsForSelectedDevicesForTheLast24hByCompanyId(
            companyId,
            { probesensors: new Set(sensorIds) },
            companyName,
            siteName,
            containerName,
            dateFrom,
            dateTo,
            language
          );
      }

      if (timeRange === "lastweek") {
        errorMessage =
          await getMeasurementReportsForSelectedDevicesForTheLastWeekByCompanyId(
            companyId,
            { probesensors: new Set(sensorIds) },
            companyName,
            siteName,
            containerName,
            dateFrom,
            dateTo,
            language
          );
      }

      if (timeRange === "lastmonth") {
        errorMessage =
          await getMeasurementReportsForSelectedDevicesForTheLastMonthByCompanyId(
            companyId,
            { probesensors: new Set(sensorIds) },
            companyName,
            siteName,
            containerName,
            dateFrom,
            dateTo,
            language
          );
      }

      if (timeRange === "lastquarter") {
        errorMessage =
          await getMeasurementReportsForSelectedDevicesForTheLastQuarterByCompanyId(
            companyId,
            { probesensors: new Set(sensorIds) },
            companyName,
            siteName,
            containerName,
            dateFrom,
            dateTo,
            language
          );
      }

      if (timeRange === "lasthalfyear") {
        errorMessage =
          await getMeasurementReportsForSelectedDevicesForTheLastHalfYearByCompanyId(
            companyId,
            { probesensors: new Set(sensorIds) },
            companyName,
            siteName,
            containerName,
            dateFrom,
            dateTo,
            language
          );
      }

      if (timeRange === "lastyear") {
        errorMessage =
          await getMeasurementReportsForSelectedDevicesForTheLastYearByCompanyId(
            companyId,
            { probesensors: new Set(sensorIds) },
            companyName,
            siteName,
            containerName,
            dateFrom,
            dateTo,
            language
          );
      }

      if (timeRange === "custom") {
        errorMessage =
          await getMeasurementReportsForSelectedDevicesForCustomDateRangeByCompanyId(
            companyId,
            { probesensors: new Set(sensorIds) },
            companyName,
            siteName,
            containerName,
            dateFrom,
            dateTo,
            language
          );
        console.log(
          "COMPONENT CODE: ",
          companyId,
          { probesensors: new Set(sensorIds) },
          companyName,
          siteName,
          containerName,
          dateFrom,
          dateTo,
          language
        );
      }

      if (errorMessage) {
        if (
          errorMessage === "No data found for the last 24 hours" ||
          errorMessage === "No data found for the last week" ||
          errorMessage === "No data found for the last month" ||
          errorMessage === "No data found for the last quarter" ||
          errorMessage === "No data found for the last half year" ||
          errorMessage === "No data found for the last year" ||
          errorMessage === "No data found for the selected date range"
        ) {
          toast.error(t("no_data_found_for_given_timeframe"));
        } else {
          toast.error(errorMessage);
        }
      } else {
        toast.success(t("report_page_success"));
      }
    } catch (error) {
      console.error("Error fetching reports: ", error);
      toast.error(t("report_page_error"));
    }
  };

  const renderSensorsPopover = (device, sensors) => {
    // Ensure device.sensors is an array before trying to map over it
    if (Array.isArray(sensors)) {
      return (
        <Popover id={`popover-positioned-bottom-${device._id}`}>
          <Popover.Header className="reports-popover__header">
            <h5>{device.probeid}</h5>
            <div className="reports-popover__header__buttons">
              <button onClick={() => handleSelectAll(device)}>
                <CheckCircleOutlined />
              </button>
              <button onClick={() => handleDeselectAll(device)}>
                <CloseCircleOutlined />
              </button>
            </div>
          </Popover.Header>
          <Popover.Body className="reports-popover__body">
            {sensors.map((sensor) => (
              <div key={sensor._id} className="reports-popover__body__item">
                <input
                  type="checkbox"
                  checked={[...selectedSensors].some(
                    (selectedSensor) =>
                      selectedSensor.sensorId === sensor._id &&
                      selectedSensor.probeId === device.probeid
                  )} // Determine if checked
                  onChange={() => handleSensorClick(sensor._id, device.probeid)} // Handle change
                />
                {t("depth")} - {sensor.sens_num}
              </div>
            ))}
          </Popover.Body>
        </Popover>
      );
    }
  };

  return (
    <div className="container reports-page__container navbar-margin">
      <PageHeader
        title={t("report_page_title")}
        helperTitle={t("report_page_title")}
        helperBody={helperText}
      />
      {userRole !== USER_ROLES.RESELLER && (
        <div className="reports-page__content">
          <div className="">
            {/* ----- SITES ----- */}
            <Form className="reports-page__content__form">
              <Form.Group className="reports-page-form-item">
                <Form.Label htmlFor="report-site">{t("site")}</Form.Label>
                {sites && sites.length > 0 ? (
                  <FormSelect
                    id="report-site"
                    onChange={(event) => handleSiteSelectChange(event)}
                  >
                    <option>{t("choose")}...</option>
                    {/*                <option value="all">Összes telephely</option> */}
                    {sites.map((site) => (
                      <option key={site._id} value={site._id}>
                        {site.sitename}
                      </option>
                    ))}
                  </FormSelect>
                ) : (
                  <div className="reports-page-form-no-list">
                    <p>{t("report_page_no_site")}</p>
                  </div>
                )}
              </Form.Group>
              {/* ----- CONTAINERS ----- */}
              <Form.Group className="reports-page-form-item">
                <Form.Label htmlFor="report-container">
                  {t("container")}
                </Form.Label>
                {containers && containers.length > 0 ? (
                  <FormSelect
                    id="report-container"
                    onChange={handleContainerSelectChange}
                  >
                    <option>{t("choose")}...</option>
                    {/*         <option value="all">Összes tároló</option> */}
                    {containers.map((container) => (
                      <option key={container._id} value={container._id}>
                        {container.containername}
                      </option>
                    ))}
                  </FormSelect>
                ) : (
                  <div className="reports-page-form-no-list">
                    <p>{t("report_page_choose_container")}</p>
                  </div>
                )}
              </Form.Group>
              {/* ----- DEVICES ----- */}
              <Form.Group className="reports-page-form-item">
                <Form.Label htmlFor="report-device">{t("device")}</Form.Label>
                <div className="reports-page-form-item__device-wrapper">
                  <div className="reports-page-form-item__device-list">
                    {" "}
                    {devices && devices.length > 0 ? (
                      devices.map((device, index) => (
                        <OverlayTrigger
                          key={device._id}
                          trigger="click"
                          placement="bottom"
                          overlay={
                            renderSensorsPopover(device, sensors) || <div></div>
                          } // Fallback to prevent errors
                          rootClose
                        >
                          <li
                            key={index}
                            className="reports-page-form-item__device__list-item"
                            style={{ cursor: "pointer" }}
                            onClick={() => handleDeviceClick(device)}
                          >
                            {device.probeid}
                          </li>
                        </OverlayTrigger>
                      ))
                    ) : (
                      <div className="reports-page-form-no-list">
                        <p>{t("report_page_no_device_in_container")}</p>
                      </div>
                    )}
                  </div>{" "}
                  <div className="reports-page-form-item__device-selection-all-buttons_wrapper">
                    <button type="button" onClick={() => handleSelectAll()}>
                      <CheckCircleOutlined />
                      <p>{t("selectAll")}</p>
                    </button>
                    <button type="button" onClick={() => handleDeselectAll()}>
                      <CloseCircleOutlined /> <p>{t("unselectAll")}</p>
                    </button>
                  </div>
                </div>
              </Form.Group>
              <Form.Group className="reports-page-form-item">
                <Form.Label htmlFor="report-date">{t("timeframe")}</Form.Label>
                <FormSelect
                  id="report-date"
                  onChange={(event) => setTimeRange(event.target.value)}
                >
                  <option value="">{t("choose")}...</option>
                  <option value="last24h">{t("last24h")}</option>
                  <option value="lastweek">{t("lastweek")}</option>
                  <option value="lastmonth">{t("lastmonth")}</option>
                  <option value="lastquarter">{t("lastquarter")}</option>
                  <option value="lasthalfyear">{t("lasthalfyear")}</option>
                  <option value="lastyear">{t("lastyear")}</option>
                  <option value="custom">{t("customTimeFrame")}</option>
                </FormSelect>
                {/*  <Form.Control type="date" id="report-date"></Form.Control> */}
              </Form.Group>
              {/* {selectedSensors.size > 0 && (
              <div>
                <h3>Kiválasztott szenzorok:</h3>
                <ul>
                  {Array.from(selectedSensors).map((sensorData, index) => {
                    let sensor;
                    try {
                      // Check if sensorData is a string and parse it, otherwise use it directly if it's already an object
                      sensor =
                        typeof sensorData === "string"
                          ? JSON.parse(sensorData)
                          : sensorData;
                    } catch (error) {
                      console.error("Error parsing sensor data:", error);
                      // Handle the error (e.g., skip this item or show a placeholder)
                      return null; // Skip this item or handle as needed
                    }
                    return (
                      <li
                        key={index}
                      >{`${sensor.probeId} - ${sensor.sensNum}`}</li>
                    );
                  })}
                </ul>
              </div>
            )} */}
              {showDatePicker && (
                <CustomTimeRangePicker
                  show={showDatePicker}
                  setShow={setShowDatePicker}
                  onDateChange={handleDateChange}
                />
              )}
              {(timeRange === "lastmonth" ||
                timeRange === "lastquarter" ||
                timeRange === "lasthalfyear" ||
                timeRange === "lastyear" ||
                timeRange === "custom") && <TimeFrameNotification />}
              <Button
                variant="primary"
                type="submit"
                onClick={handleReportSubmitClicked}
              >
                {t("report_page_submit_button")}
              </Button>
            </Form>
          </div>
        </div>
      )}
      {userRole === USER_ROLES.RESELLER && (
        <div className="reports-page__content">
          <h3>Kereskedőként nem készíthet riportokat!</h3>
        </div>
      )}
    </div>
  );
};

export default ReportsPage;
