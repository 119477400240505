export const sanitizeString = (string) => {
  const accentsMap = new Map([
    ["á", "a"],
    ["é", "e"],
    ["í", "i"],
    ["ó", "o"],
    ["ö", "o"],
    ["ő", "o"],
    ["ú", "u"],
    ["ü", "u"],
    ["ű", "u"],
    ["Á", "A"],
    ["É", "E"],
    ["Í", "I"],
    ["Ó", "O"],
    ["Ö", "O"],
    ["Ő", "O"],
    ["Ú", "U"],
    ["Ü", "U"],
    ["Ű", "U"],
  ]);

  return string
    .split("")
    .map((char) => accentsMap.get(char) || char) // replace accented characters
    .join("")
    .replace(/\s+/g, "_") // replace spaces with underscores
    .replace(/[^\w-]/g, "") // remove special characters
    .toLowerCase();
};