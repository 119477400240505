/**
 * AddNewUserModal Component
 *
 * This component is responsible for rendering a modal form to add a new user.
 * It receives `showNewUserModal`, `handleNewUserModalClose`, and `handleNewUserModalSubmit` as props, which are used to control the modal's visibility and handle form submission.
 *
 * It uses a local state `checkedUserNotification` to control the state of the notification switch in the form.
 * The `handleUserNotificationChange` function is used to update the state of the notification switch.
 *
 * The form in the modal collects various user details like last name, first name, email, phone, user role, location, and notification preference.
 *
 * The modal has two buttons in the footer, one to close the modal and another to submit the form.
 *
 * @component
 */

import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import USER_ROLES from "../../../../constants/userRoles";
import useTranslation from "../../../../hooks/useTranslation";
import useTranslatedRoles from "../../../../hooks/useTranslatedRoles";

const AddNewUserModal = ({
  showNewUserModal,
  handleNewUserModalClose,
  handleNewUserModalSubmit,
}) => {
  const { t } = useTranslation("settingsPage");
  const [formData, setFormData] = useState({
    lastname: "",
    firstname: "",
    email: "",
    phonenumber: "",
    roles: "",
    /*     location: [], */
    status: "P",
    company_id: 0,
    emailnotification: false,
  });
  // Use the useState hook to control the state of the notification switch
  const [checkedUserNotification, setCheckedUserNotification] = useState(false);

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      emailnotification: checkedUserNotification,
    }));
  }, [checkedUserNotification]);

  // Define the function to handle the change of the notification switch
  const handleUserNotificationChange = (e) => {
    setCheckedUserNotification(e.target.checked);
  };

  return (
    <>
      <Modal
        show={showNewUserModal}
        onHide={handleNewUserModalClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Új felhasználó hozzáadása</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="newuserform__lastname">
              <Form.Label>{t("lastname")}</Form.Label>
              <Form.Control
                type="text"
                placeholder="Cserepes"
                required
                onChange={(e) =>
                  setFormData({ ...formData, lastname: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="newuserform__firstname">
              <Form.Label>{t("firstname")}</Form.Label>
              <Form.Control
                type="text"
                placeholder="Virág"
                required
                onChange={(e) =>
                  setFormData({ ...formData, firstname: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="newuserform__email">
              <Form.Label>Email cím</Form.Label>
              <Form.Control
                type="email"
                placeholder="virag@ceged.hu"
                required
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="newuserform__phone">
              <Form.Label>Telefonszám</Form.Label>
              <Form.Control
                type="phone"
                placeholder="+36301234567"
                required
                onChange={(e) =>
                  setFormData({ ...formData, phonenumber: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="newuserform__userrole">
              <Form.Select
                aria-label="Szerepkör"
                onChange={(e) =>
                  setFormData({ ...formData, roles: e.target.value })
                }
              >
                <option value="">{t("selectRole")}...</option>
                <option value="1">Felhasználó</option>
                <option value="2">Telephelyvezető</option>
                <option value="3">Cégadmin</option>
                <option value="4">Kereskedő</option>
                <option value="5">Szuperadmin</option>
              </Form.Select>
            </Form.Group>
            {/* <Form.Group className="mb-3" controlId="newuserform__location ">
              <Form.Select
                aria-label="Telephely"
                multiple={true}
                onChange={(e) =>
                  setFormData({ ...formData, location: e.target.value })
                }
              >
                <option>Válasszon telephelyet...</option>
                <option value="1">Raktár 1</option>
                <option value="2">Raktár 2</option>
                <option value="3">Raktár 3</option>
              </Form.Select>
            </Form.Group> */}
            <Form.Group>
              <Form.Check
                type="switch"
                id="custom-switch"
                label="Értesítések engedélyezése"
                checked={checkedUserNotification}
                onChange={handleUserNotificationChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleNewUserModalClose}>
            Mégsem
          </Button>
          <Button
            variant="primary"
            onClick={() => handleNewUserModalSubmit(formData)}
          >
            Hozzáad
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddNewUserModal;
