/**
 * DeviceChart.jsx
 *
 * This component is responsible for rendering a line chart for a specific device.
 *
 * It uses the Chart.js library to create a line chart with zoom functionality.
 * The chart displays data from multiple datasets, with each dataset representing a different sensor.
 *
 * The data for the chart (labels and datasets) is passed in through props.
 * While the data is loading, a spinner is displayed.
 *
 * The chart configuration options are defined in the `options` object.
 * These include options for the x and y axes, tooltips, and zoom functionality.
 *
 * The `zoom` property in the `options` object enables zooming on the chart.
 * The `onZoomComplete` function is used to update the chart after a zoom operation is completed.
 *
 * The component returns a `div` containing the line chart.
 */

import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from "chart.js";
import "chartjs-adapter-moment";
import zoomPlugin from "chartjs-plugin-zoom";
import moment from "moment-timezone";
import "moment/locale/hu";
import useTranslation from "../../../../hooks/useTranslation";

moment.locale("hu");

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  zoomPlugin
);

const DeviceChartForContainerView = ({
  chartData,
  timeFormat,
  startDateForGraphTitle,
  endDateForGraphTitle,
}) => {
  const { t } = useTranslation("locationsPage");
  const [chartDataForChartJs, setChartDataForChartJs] = useState({
    labels: [],
    datasets: [],
  });

  useEffect(() => {
    // Get user's timezone
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Adjust timestamps to user's timezone
    const adjustedChartData = chartData.map((item) => ({
      ...item,
      sens_timestamp: moment.utc(item.sens_timestamp).tz(userTimezone).format(),
    }));

    // Sort chart data by timestamp
    const sortedChartData = [...adjustedChartData].sort(
      (a, b) => new Date(a.sens_timestamp) - new Date(b.sens_timestamp)
    );
    console.log("Sorted chartData:", sortedChartData);

    // Get unique probe and sensor combinations
    const uniqueProbesAndSensors = [
      ...new Set(
        sortedChartData.map((item) => `${item.probeid}-${item.sens_num}`)
      ),
    ];

    // Get unique timestamps
    const uniqueTimestamps = [
      ...new Set(sortedChartData.map((item) => item.sens_timestamp)),
    ].sort((a, b) => new Date(a) - new Date(b));

    // Create datasets
    const datasets = uniqueProbesAndSensors.map((probeAndSensor) => {
      const [probeid, sens_num] = probeAndSensor
        .split("-")
        .map((item, index) =>
          index === 0 ? item.toString() : parseInt(item, 10)
        ); // Convert probeid to string, sens_num to number
      const sensorData = sortedChartData.filter((item) => {
        return (
          item.probeid.toString() === probeid && item.sens_num === sens_num
        ); // Ensure probeid is compared as a string
      });

      // Create a map of timestamp to value
      const dataMap = new Map(
        sensorData.map((item) => [item.sens_timestamp, item.sens_value])
      );

      // Fill the data array with values corresponding to the unique timestamps
      const data = uniqueTimestamps.map((timestamp) =>
        dataMap.get(timestamp) !== undefined ? dataMap.get(timestamp) : null
      );

      const bgColor = "#" + Math.floor(Math.random() * 16777215).toString(16);

      return {
        label: `${probeid} - ${sens_num}`,
        data,
        fill: false,
        backgroundColor: bgColor,
        borderColor: bgColor,
        spanGaps: true, // This option connects the points even if there are gaps
      };
    });

    // Update chart data state
    setChartDataForChartJs({ labels: uniqueTimestamps, datasets });
  }, [chartData]);

  // Chart Title
  // Format the start and end dates for the chart title
  const formatDate = (date) => {
    return moment(date).format("YYYY.MM.DD HH:mm");
  };

  const formattedStartDate = formatDate(startDateForGraphTitle);
  const formattedEndDate = formatDate(endDateForGraphTitle);

  // Determine the title based on the time format
  const titleText =
    timeFormat === "last24h"
      ? `${t("time")}, ${formattedStartDate} - ${formattedEndDate}`
      : `${t("time")}, ${formattedStartDate.split(" ")[0]} - ${
          formattedEndDate.split(" ")[0]
        }`;

  // Chart configuration options
  const options = {
    animation: false,
    pointRadius: 0.5,
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: "time",
        time: {
          unit: timeFormat === "last24h" ? "hour" : "day",
          tooltipFormat: timeFormat === "last24h" ? "HH:mm" : "MM/DD",
          displayFormats: {
            hour: "HH:mm",
            day: "MM.DD",
          },
        },
        title: {
          display: true,
          text: titleText,
        },
      },
      y: {
        beginAtZero: false,
        title: {
          display: true,
          text: t("measured_value_for_graph"),
        },
      },
    },
    plugins: {
      tooltip: {
        intersect: false,
        mode: "index",
        callbacks: {
          label: function (context) {
            const label = context.dataset.label || "";
            const value = context.raw;
            return `${label}: ${Math.round(value * 100) / 100}`;
          },
        },
      },
      zoom: {
        pan: {
          enabled: true,
          mode: 'xy',
        },
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true,
          },
          mode: 'xy',
        },
      },
    },
  };

  console.log("chartDataForChartJs", chartDataForChartJs);

  return (
    // Render the line chart if there is chartdata
    <>
      {chartDataForChartJs.datasets.length === 0 ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "200px" }}
        >
          <div>
            <p>{t("no_data_for_selected_timeframe")}.</p>
          </div>
        </div>
      ) : (
        <div style={{ width: "100%", height: "400px" }}>
          <Line options={options} data={chartDataForChartJs} />
        </div>
      )}
    </>
  );
};

export default DeviceChartForContainerView;
