/**
 * ImageUpload Component
 * 
 * This component allows users to upload an image for a specific container. The image is selected,
 * compressed, and then uploaded to the server. The component uses React hooks for state management
 * and interacts with an API to upload the image.
 * 
 * Props:
 * - containerId: The ID of the container for which the image is being uploaded.
 * 
 * Key Features:
 * - Select an image file from the user's device.
 * - Compress the selected image to reduce file size.
 * - Upload the compressed image to the server.
 * - Display error messages if the image compression or upload fails.
 * 
 * Example usage:
 * <ImageUpload containerId="12345" />
 */

import React, { useState } from "react";
import { readAndCompressImage } from "browser-image-resizer";
import { uploadContainerImage } from "../../../api/imageUploadApi";
import "./ImageUpload.scss";
import { toast } from "react-toastify";
import useTranslation from "../../../hooks/useTranslation";

const ImageUpload = ({ containerId }) => {
  const [file, setFile] = useState(null);
  const [compressedFile, setCompressedFile] = useState(null);
  const { t } = useTranslation("locationsPage");

  const handleFileSelection = async (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);

    const config = {
      quality: 0.7,
      maxWidth: 800,
      maxHeight: 800,
      autoRotate: true,
      debug: true,
    };

    try {
      const compressed = await readAndCompressImage(selectedFile, config);
      setCompressedFile(compressed);
    } catch (error) {
      console.error("Error compressing image:", error);
    }
  };

  const handleFileUpload = async () => {
    if (!compressedFile) {
      console.error("No file selected");
      return;
    }

    try {
      const response = await uploadContainerImage(containerId, compressedFile);
      window.location.reload();
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  return (
    <div className="image-upload">
      <input
        type="file"
        onChange={handleFileSelection}
        accept="image/png, image/jpeg"
      />
      <button onClick={handleFileUpload} disabled={!file} className="btn btn-primary submit-picture__button">
        {t("upload_floorplan")}
      </button>
    </div>
  );
};

export default ImageUpload;
