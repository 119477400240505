import axios from "axios";
import { getAuthHeaders } from "./auth";
const backendUrl = process.env.REACT_APP_BACKEND_URL_FOR_FRONTEND;
const token = localStorage.getItem("token");

//GET

export const getSiteNameBySiteId = async (siteId) => {
  try {
    const response = await axios.get(
      `${backendUrl}/locations/sites/name/${siteId}`,
      {
        headers: getAuthHeaders(),
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching site: ", error);
  }
};

export const getContainerNameByContainerId = async (containerId) => {
  try {
    const response = await axios.get(
      `${backendUrl}/locations/containers/name/${containerId}`,
      {
        headers: getAuthHeaders(),
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching container: ", error);
  }
};

export const getAllSites = async () => {
  try {
    const sites = await axios.get(`${backendUrl}/locations/sites`, {
      headers: getAuthHeaders(),
    });
    console.log("sites", sites);
    return sites.data;
  } catch (error) {
    console.error("Error fetching sites: ", error);
  }
};

export const getAllSitesByCompanyId = async (companyId) => {
  try {
    const sites = await axios.get(
      `${backendUrl}/locations/sites/company/${companyId}`,
      {
        headers: getAuthHeaders(),
      }
    );
    return sites;
  } catch (error) {
    console.error("Error fetching sites: ", error);
  }
};

export const getSiteBySiteId = async (siteId) => {
  try {
    const site = await axios.get(`${backendUrl}/locations/sites/${siteId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return site;
  } catch (error) {
    console.error("Error fetching site: ", error);
  }
};

export const checkSiteNameExists = async (
  siteName,
  companyId,
  excludeLocationId = null
) => {
  try {
    const response = await axios.get(
      `${backendUrl}/locations/sites/checkname`,
      {
        params: { siteName, companyId, excludeLocationId },
        headers: getAuthHeaders(),
      }
    );
    return response.data.exists;
  } catch (error) {
    console.error("Error checking site name: ", error);
    return false; // Return false in case of an error
  }
};

export const getAllContainers = async () => {
  const backendUrl = process.env.REACT_APP_BACKEND_URL_FOR_FRONTEND;
  try {
    const containers = await axios.get(`${backendUrl}/containers`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return containers.data;
  } catch (error) {
    console.error("Error fetching containers: ", error);
  }
};

export const getAllContainerDataByContainerId = async (containerId) => {
  try {
    const container = await axios.get(
      `${backendUrl}/locations/containers/${containerId}`,
      {
        headers: getAuthHeaders(),
      }
    );
    return container.data;
  } catch (error) {
    console.error("Error fetching container: ", error);
  }
};

export const getAllDeviceDataByContainerId = async (containerId) => {
  try {
    const devices = await axios.get(
      `${backendUrl}/locations/containers/devices/${containerId}`,
      {
        headers: getAuthHeaders(),
      }
    );
    return devices.data;
  } catch (error) {
    console.error("Error fetching devices: ", error);
  }
};

export const getAllContainersBySiteId = async (siteId) => {
  try {
    const containers = await axios.get(
      `${backendUrl}/locations/containers/site/id/${siteId}`,
      {
        headers: getAuthHeaders(),
      }
    );
    return containers;
  } catch (error) {
    console.error("Error fetching containers: ", error);
  }
};

export const getAllContainersBySiteName = async (siteName) => {
  try {
    const containers = await axios.get(
      `${backendUrl}/locations/containers/site/name/${siteName}`,
      {
        headers: getAuthHeaders(),
      }
    );
    return containers.data;
  } catch (error) {
    console.error("Error fetching containers: ", error);
  }
};

export const getAllContainersByCompanyId = async (companyId) => {
  try {
    const containers = await axios.get(
      `${backendUrl}/locations/containers/company/${companyId}`,
      {
        headers: getAuthHeaders(),
      }
    );
    return containers;
  } catch (error) {
    console.error("Error fetching containers: ", error);
  }
};

export const getAllContainersWithBaseCountBySiteByCompanyId = async (
  siteId
) => {
  try {
    const containers = await axios.get(
      `${backendUrl}/locations/containers/basecountbysite/${siteId}`,
      {
        headers: getAuthHeaders(),
      }
    );
    return containers;
  } catch (error) {
    console.error("Error fetching containers: ", error);
  }
};

export const getAllContainersWithNoBaseByCompanyId = async (companyId) => {
  try {
    const containers = await axios.get(
      `${backendUrl}/locations/containers/nobase/${companyId}`,
      {
        headers: getAuthHeaders(),
      }
    );
    console.log("containers", containers);
    return containers;
  } catch (error) {
    console.error("Error fetching containers: ", error);
  }
};

export const getContainerImageByContainerId = async (containerId) => {
  try {
    const containerImage = await axios.get(
      `${backendUrl}/locations/containers/image/${containerId}`,
      {
        headers: getAuthHeaders(),
      }
    );
    return containerImage;
  } catch (error) {
    console.error("Error fetching container image: ", error);
  }
};

export const getAllCropsByContainerId = async (containerId) => {
  try {
    const crops = await axios.get(
      `${backendUrl}/locations/containers/crops/${containerId}`,
      {
        headers: getAuthHeaders(),
      }
    );
    return crops;
  } catch (error) {
    console.error("Error fetching crops: ", error);
  }
};

//POST

export const addNewSiteByCompanyId = async (companyId, siteData) => {
  console.log("siteData", siteData);
  try {
    const newSite = await axios.post(
      `${backendUrl}/locations/sites/${companyId}`,
      siteData,
      {
        headers: getAuthHeaders(),
      }
    );
    return newSite;
  } catch (error) {
    console.error("Error adding new site: ", error);
  }
};

export const addNewContainerBySiteId = async (containerData) => {
  console.log("containerData", containerData);
  try {
    const newContainer = await axios.post(
      `${backendUrl}/locations/containers`,
      containerData,
      {
        headers: getAuthHeaders(),
      }
    );
    return newContainer;
  } catch (error) {
    console.error("Error adding new container: ", error);
  }
};

//PUT, PATCH

export const updateSiteBySiteId = async (siteId, updatedFields) => {
  console.log("updatedFields", updatedFields);
  try {
    const updatedSite = await axios.put(
      `${backendUrl}/locations/sites/${siteId}`,
      updatedFields,
      {
        headers: getAuthHeaders(),
      }
    );
    return updatedSite;
  } catch (error) {
    console.error("Error updating site: ", error);
  }
};

export const updateContainerNameByContainerId = async (
  containerId,
  containerName
) => {
  try {
    const updatedContainer = await axios.patch(
      `${backendUrl}/locations/containers/${containerId}`,
      { containername: containerName },
      {
        headers: getAuthHeaders(),
      }
    );
    return updatedContainer;
  } catch (error) {
    console.error("Error updating container: ", error);
  }
};

//DELETE

export const deleteContainerByContainerId = async (containerId) => {
  try {
    const container = await axios.delete(
      `${backendUrl}/locations/containers/${containerId}`,
      {
        headers: getAuthHeaders(),
      }
    );
    return container;
  } catch (error) {
    console.error("Error deleting container: ", error);
  }
};

export const deleteSiteBySiteId = async (siteId) => {
  try {
    const site = await axios.delete(`${backendUrl}/locations/sites/${siteId}`, {
      headers: getAuthHeaders(),
    });
    return site;
  } catch (error) {
    console.error("Error deleting site: ", error);
  }
};
