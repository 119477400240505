import { checkSiteNameExists } from "../api/locationApi";

const validateNewSiteData = async (siteData, user, t) => {
  const errors = {};

  // Check if the sitename is already in the database within the same company
  const siteNameExists = await checkSiteNameExists(
    siteData.siteName,
    user.company_id
  );

  if (siteNameExists) {
    errors.sitenameExists = t("update_site_data_error_sitename_exists");
  }

  if (
    !siteData.siteName ||
    siteData.siteName.length < 3 ||
    siteData.siteName.length > 50
  ) {
    errors.sitenameLength = t("update_site_data_error_sitename_length");
  }

  if (
    siteData.zip.length < 4 ||
    siteData.zip.length > 5 ||
    !/^\d{4,5}$/.test(siteData.zip)
  ) {
    errors.zip = t("update_company_data_error_zip");
  }

  return errors;
};

export default validateNewSiteData;
