import axios from "axios";
import { getAuthHeaders } from "./auth";
const backendUrl = process.env.REACT_APP_BACKEND_URL_FOR_FRONTEND;
const token = localStorage.getItem("token");
const userId = localStorage.getItem("userId");

export const getAllDevices = async () => {
  try {
    const devices = await axios.get(`${backendUrl}/devices`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return devices.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getAllDevicesCountNumberByCompany = async (companyId) => {
  try {
    const devices = await axios.get(
      `${backendUrl}/devices/count/${companyId}`,
      {
        headers: getAuthHeaders(),
      }
    );
    return devices.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getAllDevicesByCompanyId = async (companyId) => {
  try {
    const devices = await axios.get(
      `${backendUrl}/devices/companydevices/${companyId}`,
      {
        headers: getAuthHeaders(),
      }
    );
    return devices.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getAllDevicesWithNoContainerByCompanyId = async (companyId) => {
  try {
    const devices = await axios.get(
      `${backendUrl}/devices/companydevicesnocontainer/${companyId}`,
      {
        headers: getAuthHeaders(),
      }
    );
    return devices.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getAllSensorsByDeviceId = async (deviceId) => {
  try {
    const sensors = await axios.get(
      `${backendUrl}/devices/sensors/${deviceId}`,
      {
        headers: getAuthHeaders(),
      }
    );
    return sensors.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getAllDeviceDataByDeviceId = async (deviceId) => {
  try {
    const deviceData = await axios.get(
      `${backendUrl}/devices/device/${deviceId}`,
      {
        headers: getAuthHeaders(),
      }
    );
    return deviceData.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getLast24hDataByDeviceId = async (deviceId) => {
  try {
    const deviceData = await axios.get(
      `${backendUrl}/devices/last24h/${deviceId}`,
      {
        headers: getAuthHeaders(),
      }
    );
    return deviceData.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getLastWeekDataByDeviceId = async (deviceId) => {
  try {
    const deviceData = await axios.get(
      `${backendUrl}/devices/lastweek/${deviceId}`,
      {
        headers: getAuthHeaders(),
      }
    );
    return deviceData.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getLastMonthDataByDeviceId = async (deviceId) => {
  try {
    const deviceData = await axios.get(
      `${backendUrl}/devices/lastmonth/${deviceId}`,
      {
        headers: getAuthHeaders(),
      }
    );
    return deviceData.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getCustomDataByDeviceIdAndDuration = async (
  deviceId,
  start,
  end
) => {
  try {
    const deviceData = await axios.get(
      `${backendUrl}/devices/custom/${deviceId}`,
      {
        params: {
          start,
          end,
        },
        headers: getAuthHeaders(),
      }
    );
    return deviceData.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getCompanyNameByDeviceId = async (deviceId) => {
  try {
    const company = await axios.get(
      `${backendUrl}/devices/companyname/${deviceId}`,
      {
        headers: getAuthHeaders(),
      }
    );
    return company.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getAllDevicesWithErrorStatus = async (companyId, userToken) => {
  if (!userToken) {
    throw new Error("Token is not set. Please login first.");
  }

  try {
    const devices = await axios.get(
      `${backendUrl}/devices/error/${companyId}`,
      {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      }
    );
    console.log(
      "Devices with error status fetched successfully:",
      devices.data
    ); // log the response
    return devices.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

// PUT, PATCH /devices

export const updateDeviceCoordinates = async (
  deviceId,
  newXCoordinate,
  newYCoordinate
) => {
  try {
    console.log("User ID (Frontend):", userId);
    const updatedDevice = await axios.patch(
      `${backendUrl}/devices/coordinates/${deviceId}`,
      { coord_x: newXCoordinate, coord_y: newYCoordinate, userId: userId },
      {
        headers: getAuthHeaders(),
      }
    );
    console.log("Device coordinates updated successfully:", updatedDevice); // log the response
    return updatedDevice.data;
  } catch (error) {
    console.error(error); // log any errors
    throw new Error(error.message);
  }
};

export const updateDeviceContainer = async (deviceId, containerId) => {
  try {
    const response = await axios.patch(
      `${backendUrl}/devices/container/${deviceId}`,
      { container_id: containerId, userId: userId },
      {
        headers: getAuthHeaders(),
      }
    );
    return response.data;
  } catch (error) {
    console.error(error); // log any errors
    throw new Error(error.message);
  }
};

export const updateDeviceStatus = async (deviceId, status) => {
  try {
    const updatedDevice = await axios.patch(
      `${backendUrl}/devices/status/${deviceId}`,
      { status: status },
      {
        headers: getAuthHeaders(),
      }
    );
    console.log("Device status updated successfully:", updatedDevice); // log the response
    return updatedDevice.data;
  } catch (error) {
    console.error(error);
    throw new Error(error.message);
  }
};
