/**
 * ContainerMoreInfoPage Component
 *
 * Summary:
 * This component represents the detailed information page for a specific container. It allows users to view and manage various aspects of a container, including its devices, crops, sensor thresholds, and graphical data.
 *
 * Key functionalities:
 * - Fetches and displays container image and device data.
 * - Allows users to move devices within the container's floor plan.
 * - Fetches and displays crops associated with the container.
 * - Fetches and updates sensor thresholds for the container.
 * - Provides a graphical view of sensor data over time.
 * - Utilizes the toast notification library to display notifications.
 * - Dynamically adjusts the layout based on the screen width.
 * - Implements various data fetching functions for different views.
 * - Handles user interactions and updates data accordingly.
 * - Includes a helper modal to guide users on the page functionalities.
 */

import React, { useContext, useState, useEffect, useRef } from "react";
import UserContext from "../../../context/UserContext";
import { useLocation } from "react-router-dom";
import {
  getContainerImageByContainerId,
  getAllDeviceDataByContainerId,
  getAllCropsByContainerId,
} from "../../../api/locationApi";
import {
  getAllSensorsByContainerId,
  getAllSensorsWithEveryDataByContainerId,
  updateEverySensorThresholdsByContainerId,
  updateEverySensorThresholdsAdditiveByContainerId,
  getAllSensorsWithNoMesDataByContainerId,
} from "../../../api/sensorApi";
import {
  connectCropToContainer,
  deleteCropFromContainer,
  getAllCropsByCompanyId,
} from "../../../api/cropApi";
import { useScreenWidth } from "../../../hooks/useScreenWidth";
import ContainerFloorPlanView from "./ContainerFloorPlanView/ContainerFloorPlanView";
import ContainerCropView from "./ContainerCropView/ContainerCropView";
import ContainerThresholdsView from "./ContainerThresholdsView/ContainerThresholdsView";
import { Button, ButtonGroup } from "react-bootstrap";
import { toast } from "react-toastify";
import "./ContainerMoreInfoPage.scss";
import ContainerGraphView from "./ContainerGraphView/ContainerGraphView";
import ConfirmationModal from "../../Shared/ConfirmationModal/ConfirmationModal";
import useTranslation from "../../../hooks/useTranslation";
import PageHeader from "../../Shared/PageHeader/PageHeader";

const ContainerMoreInfoPage = () => {
  const { user } = useContext(UserContext);
  const { t } = useTranslation("locationsPage");
  const imageRef = useRef();
  const location = useLocation();
  /*   const { container = { containername: "default-containername" } } =
    location.state || {}; */
  const container = location.state?.container
    ? {
        ...location.state.container,
        _id:
          location.state.container.containers_id ||
          location.state.container._id,
      }
    : {};

  // State variables
  // Image data for the container floor plan
  const [imageData, setImageData] = useState(null);
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
  const [showImageUpload, setShowImageUpload] = useState(false);

  // Device data for the container floor plan
  const [devices, setDevices] = useState([]);

  // Sensor data for the sensor threshold view
  const [
    sensorsWithThresholdsInContainer,
    setSensorsWithThresholdsInContainer,
  ] = useState([]);

  // Selected sensors for the graph view
  const [selectedSensors, setSelectedSensors] = useState({});
  const [sensorsForSelectionInGraphView, setSensorsForSelectionInGraphView] =
    useState([]);
  const [graphData, setGraphData] = useState([]);

  // CROP VIEW STATES
  // Crop data for the crop view
  const [crops, setCrops] = useState([]);
  const [listOfCropsInCompany, setListOfCropsInCompany] = useState([]);
  const [showConnectCropToContainerModal, setShowConnectCropToContainerModal] =
    useState(false);
  const [
    selectedCropToConnectToContainer,
    setSelectedCropToConnectToContainer,
  ] = useState(null);
  const [
    selectedCropToDeleteFromContainer,
    setSelectedCropToDeleteFromContainer,
  ] = useState(null);
  const [showCropDeleteConfirmationModal, setShowCropDeleteConfirmationModal] =
    useState(false);

  // View state
  const [view, setView] = useState("map");

  // Constants
  const screenWidth = useScreenWidth();
  const thresholdViewTableHeader = {
    sens_num: t("depth"),
    sens_timestamp: t("last_meas_time"),
    sens_value: t("measuredValue") + " (°C)",
    min_value: t("minValue"),
    max_value: t("maxValue"),
  };
  const sensorMinThreshold = -55;
  const sensorMaxThreshold = 125;
  const sumOfThresholds = sensorMaxThreshold + sensorMinThreshold * -1;

  // ---------------- DEVICE MAP VIEW FUNCTIONS ----------------
  // Fetch container image and device data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const containerImage = await getContainerImageByContainerId(
          container._id
        );
        if (containerImage && containerImage.data) {
          setImageData(containerImage.data[0]);
        } else {
          console.error("No container image found for id:", container._id);
        }

        const devicesData = await getAllDeviceDataByContainerId(container._id);

        let nextDefaultXPosition = 0; // Reset next default position
        let firstDevice = true; // Flag to track the first device

        const DEFAULT_MARGIN = screenWidth < 576 ? 10 : 5; // Set default margin based on screen width

        const formattedDevices = devicesData.map((device) => {
          let x;
          let y = device.coord_y || 0;

          // Calculate x position based on the default margin
          if (device.coord_x === undefined || device.coord_x === null) {
            if (firstDevice) {
              firstDevice = false;
              x = 0; // Start from the left for the first device
            } else {
              x = nextDefaultXPosition;
            }
            nextDefaultXPosition += DEFAULT_MARGIN; // Update default position for the next device
          } else {
            x = device.coord_x;
          }

          return {
            ...device,
            x,
            y,
          };
        });

        setDevices(formattedDevices);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchData();
  }, [container._id]);

  // Update device position when moved
  const handleDeviceMove = (id, percentX, percentY) => {
    /*     console.log(
      "handleDeviceMove percentX, percentY:",
      percentX,
      percentY,
      "id:",
      id
    ); */

    // Update device position with percentage-based coordinates
    setDevices((prevDevices) =>
      prevDevices.map((device) =>
        device.probeid === id
          ? { ...device, coord_x: percentX, coord_y: percentY }
          : device
      )
    );
  };

  //  ---------------- CROP VIEW FUNCTIONS ----------------

  const fetchCrops = async () => {
    try {
      const cropsInContainer = await getAllCropsByContainerId(container._id);
      setCrops(cropsInContainer);
      const userCompanyId = parseInt(user.companyId);
      const cropsInCompany = await getAllCropsByCompanyId(userCompanyId);
      setListOfCropsInCompany(cropsInCompany);
    } catch (error) {
      console.error("ContainerMoreInfoPage: Error fetching crops: ", error);
    }
  };

  useEffect(() => {
    fetchCrops();
  }, [container._id]);

  // Handle connect crop to container button click
  const handleSubmitConnectCropToContainerButtonClick = async (
    containerId,
    cropId
  ) => {
    try {
      if (!containerId || !cropId || cropId === null || cropId === "") {
        toast.error(t("must_select_crop"));
        return;
      }

      const response = await connectCropToContainer(containerId, cropId);
      console.log("response", response);
      if (response) {
        fetchCrops();
        toast.success(t("crop_connection_to_container_success"));
        setSelectedCropToConnectToContainer(null);
        handleCloseConnectCropToContainerModal();
      }
    } catch (error) {
      console.error("Error connecting crop to container: ", error);
      toast.error(t("crop_connection_to_container_error"));
    }
  };

  // Handle show connect crop to container modal
  const handleShowConnectCropToContainerModal = () => {
    setSelectedCropToConnectToContainer(null);
    setShowConnectCropToContainerModal(true);
  };

  // Handle close connect crop to container modal
  const handleCloseConnectCropToContainerModal = () => {
    setSelectedCropToConnectToContainer(null);
    setShowConnectCropToContainerModal(false);
  };

  // Handle show crop delete confirmation modal
  const handleShowCropDeleteConfirmationModal = (cropId) => {
    setSelectedCropToDeleteFromContainer(cropId);
    setShowCropDeleteConfirmationModal(true);
  };

  // Handle delete crop from container
  const handleDeleteCropFromContainerConfirm = async () => {
    if (!selectedCropToDeleteFromContainer) {
      toast.error("Törléshez nincs kiválasztva termény");
      return;
    }

    try {
      const response = await deleteCropFromContainer(
        container._id,
        selectedCropToDeleteFromContainer
      );
      if (response) {
        fetchCrops();
        toast.success("A termény sikeresen eltávolítva a konténerből");
        setSelectedCropToDeleteFromContainer(null);
        setShowCropDeleteConfirmationModal(false);
      }
    } catch (error) {
      console.error("Error deleting crop from container: ", error);
      toast.error(
        "Hiba történt a termény konténerből való eltávolítása közben"
      );
    }
  };

  // Handle close crop delete confirmation modal
  const handleDeleteCropFromContainerCancel = () => {
    setSelectedCropToDeleteFromContainer(null);
    setShowCropDeleteConfirmationModal(false);
  };

  // ---------------- SENSOR THRESHOLD VIEW FUNCTIONS ----------------
  // Fetch sensors with thresholds
  const fetchSensorsWithThresholds = async () => {
    try {
      const sensors = await getAllSensorsByContainerId(container._id);
      console.log("sensors", sensors);
      setSensorsWithThresholdsInContainer(sensors);
    } catch (error) {
      console.error("Error fetching sensors with thresholds: ", error);
    }
  };

  // Update sensor thresholds
  useEffect(() => {
    fetchSensorsWithThresholds();
    console.log("sensor with thresholds", sensorsWithThresholdsInContainer);
  }, [container._id]);

  // Handle save button click for updating sensor thresholds
  const handleThresholdSaveClick = async (minThreshold, maxThreshold) => {
    const containerId = container._id;
    minThreshold = parseFloat(minThreshold);
    maxThreshold = parseFloat(maxThreshold);

    const thresholds = {
      minThreshold,
      maxThreshold,
    };

    if (minThreshold === null || maxThreshold === null) {
      toast.error(t("thresholds_cannot_be_empty"));
      return;
    }

    if (isNaN(minThreshold) || isNaN(maxThreshold)) {
      toast.error(t("thresholds_must_be_numbers"));
      return;
    }

    if (minThreshold % 1 !== 0 || maxThreshold % 1 !== 0) {
      toast.error(t("thresholds_must_be_whole_numbers"));
    }

    if (minThreshold >= maxThreshold) {
      toast.error(t("min_threshold_must_be_less_than_max_threshold"));
      return;
    }

    if (
      minThreshold < sensorMinThreshold ||
      minThreshold > sensorMaxThreshold ||
      maxThreshold < sensorMinThreshold ||
      maxThreshold > sensorMaxThreshold
    ) {
      toast.error(
        `${t(
          "threshold_values_must_be_between"
        )}: ${sensorMinThreshold} - ${sensorMaxThreshold}`
      );
      return;
    }

    try {
      const response = await updateEverySensorThresholdsByContainerId(
        containerId,
        thresholds
      );

      if (response) {
        fetchSensorsWithThresholds();
        toast.success(t("thresholds_updated_successfully"));
      }
    } catch (error) {
      console.error("Error updating thresholds: ", error);
    }
  };

  // Handle save button click for updating sensor thresholds with additive values
  const handleThresholdAdditiveSaveClick = async (minAdditive, maxAdditive) => {
    const containerId = container._id;
    minAdditive = parseFloat(minAdditive);
    maxAdditive = parseFloat(maxAdditive);

    const additives = {
      minAdditive,
      maxAdditive,
    };

    if (minAdditive === null || maxAdditive === null) {
      toast.error(t("additive_values_cannot_be_empty"));
      return;
    }

    if (isNaN(minAdditive) || isNaN(maxAdditive)) {
      toast.error(t("additive_values_must_be_numbers"));
      return;
    }

    if (minAdditive % 1 !== 0 || maxAdditive % 1 !== 0) {
      toast.error(t("additive_values_must_be_whole_numbers"));
    }

    if (minAdditive > maxAdditive) {
      toast.error(t("min_additive_must_be_less_than_max_additive"));
      return;
    }

    try {
      const response = await updateEverySensorThresholdsAdditiveByContainerId(
        containerId,
        additives
      );

      console.log("response", response);
      if (response) {
        fetchSensorsWithThresholds();
        toast.success(t("additive_values_updated_successfully"));
      }

      // Check if response.details exists and is an array
      if (
        response.details &&
        Array.isArray(response.details) &&
        response.details.length > 0
      ) {
        for (const detail of response.details) {
          console.log("detail", detail);
          toast.warning(
            `Az alábbi szenzor additív módosítása nem sikerült: ${detail.probeId} - Mélység: ${detail.sens_num}`
          );
        }
      }
    } catch (error) {
      console.log("error", error);
      console.error("Error updating additives: ", error);
      fetchSensorsWithThresholds();
      toast.error(t("additive_values_update_error"));
    }
  };

  // ---------------- GRAPH VIEW FUNCTIONS ----------------
  // Fetch sensor data for the graph view
  useEffect(() => {
    const fetchSensorsInContainer = async () => {
      try {
        const sensors = await getAllSensorsWithEveryDataByContainerId(
          container._id
        );
        const sensorsData = sensors.map((sensor) => {
          return {
            sensor_id: sensor.sensor_id,
            probeid: sensor.probeid,
            sens_num: sensor.sens_num,
            sens_value: sensor.sens_value,
            sens_timestamp: sensor.sens_timestamp,
          };
        });

        setGraphData(sensorsData);
      } catch (error) {
        console.error("Error fetching sensors: ", error);
      }
    };
    fetchSensorsInContainer();
  }, [container._id]);

  // Fetch sensors with no measurement data for the graph view
  useEffect(() => {
    const fetchSensorsWithNoMesDataInContainer = async () => {
      try {
        const sensors = await getAllSensorsWithNoMesDataByContainerId(
          container._id
        );
        setSensorsForSelectionInGraphView(sensors);
      } catch (error) {
        console.error("Error fetching sensors with no mes data: ", error);
      }
    };
    fetchSensorsWithNoMesDataInContainer();
  }, [container._id]);

  // Handle sensor selection for the graph view
  const handleSensorSelection = (deviceId, sensorId) => {
    setSelectedSensors((prev) => ({ ...prev, [deviceId]: sensorId }));
  };

  return (
    <div className="container locations-page__container navbar-margin">
      <PageHeader
        title={container.containername}
        helperTitle={container.containername}
        helperBody={t("container_more_info_page_helper")}
      />

      <div className="container-moreinfo-page__view-buttons__container">
        <ButtonGroup
          aria-label="Basic example"
          size="sm"
          className="container-view-button-group"
        >
          <Button
            variant="secondary"
            className="container-view-button"
            onClick={() => setView("map")}
          >
            {t("container")}
          </Button>
          <Button
            variant="secondary"
            className="container-view-button"
            onClick={() => setView("sensor")}
          >
            {t("sensor")}
          </Button>{" "}
          <Button
            variant="secondary"
            className="container-view-button"
            onClick={() => setView("graph")}
          >
            {t("graph")}
          </Button>
          <Button
            variant="secondary"
            className="container-view-button"
            onClick={() => setView("crop")}
          >
            {t("crop")}
          </Button>
        </ButtonGroup>
      </div>

      {view === "map" && (
        <ContainerFloorPlanView
          devices={devices}
          handleDeviceMove={handleDeviceMove}
          imageData={imageData}
          imageRef={imageRef}
          imageSize={imageSize}
          setImageSize={setImageSize}
          showImageUpload={showImageUpload}
          setShowImageUpload={setShowImageUpload}
          containerId={container._id}
        />
      )}

      {view === "sensor" && (
        <ContainerThresholdsView
          containerId={container._id}
          sensorsTableHeader={thresholdViewTableHeader}
          sensorsData={sensorsWithThresholdsInContainer}
          handleThresholdSaveClick={handleThresholdSaveClick}
          handleThresholdAdditiveSaveClick={handleThresholdAdditiveSaveClick}
          sensorMinThreshold={sensorMinThreshold}
          sensorMaxThreshold={sensorMaxThreshold}
        />
      )}
      {view === "graph" && (
        <ContainerGraphView
          devices={devices}
          sensorsForSelectionInGraphView={sensorsForSelectionInGraphView}
          graphData={graphData}
        />
      )}
      {view === "crop" && (
        <ContainerCropView
          containerId={container._id}
          container={container}
          cropData={crops.data}
          listOfCropsInCompany={listOfCropsInCompany}
          showConnectCropToContainerModal={showConnectCropToContainerModal}
          handleSubmitConnectCropToContainerButtonClick={
            handleSubmitConnectCropToContainerButtonClick
          }
          handleShowConnectCropToContainerModal={
            handleShowConnectCropToContainerModal
          }
          handleCloseConnectCropToContainerModal={
            handleCloseConnectCropToContainerModal
          }
          selectedCropToConnectToContainer={selectedCropToConnectToContainer}
          setSelectedCropToConnectToContainer={
            setSelectedCropToConnectToContainer
          }
          handleShowCropDeleteConfirmationModal={
            handleShowCropDeleteConfirmationModal
          }
          selectedCropToDeleteFromContainer={selectedCropToDeleteFromContainer}
          setSelectedCropToDeleteFromContainer={
            setSelectedCropToDeleteFromContainer
          }
        />
      )}
      {showCropDeleteConfirmationModal && (
        <ConfirmationModal
          isVisible={showCropDeleteConfirmationModal}
          onConfirm={handleDeleteCropFromContainerConfirm}
          onCancel={handleDeleteCropFromContainerCancel}
          message={`Biztosan törli a terményt a tárolóból?`}
        />
      )}
    </div>
  );
};
export default ContainerMoreInfoPage;
