import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { PlusCircleOutlined } from "../../../helpers/icons/antDesignIcons";
import "../LocationsPage.scss";
import useTranslation from "../../../hooks/useTranslation";

const AddSite = ({ handleAddNewSiteClick }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [siteName, setSiteName] = useState("");
  const [siteCity, setSiteCity] = useState("");
  const [zip, setZip] = useState("");
  const [siteStreet, setSiteStreet] = useState("");
  const [siteHouseNr, setSiteHouseNr] = useState("");
  const { t } = useTranslation("common");

  return (
    <div className="add-site__wrapper">
      <Button
        variant="contained"
        color="primary"
        className="locations-page__header__add__btn"
        onClick={() => setModalIsOpen(true)}
      >
        <PlusCircleOutlined /> {t("addSite")}
      </Button>
      <Modal centered show={modalIsOpen} onHide={() => setModalIsOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title> {t("addSite")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="add-site__form">
            <Form.Group className="mb-3">
              <Form.Label>{t("siteName")}</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("siteName")}
                required
                onChange={(e) => setSiteName(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>{t("city")}</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("city")}
                required
                onChange={(e) => setSiteCity(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>{t("zip")}</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("zip")}
                required
                onChange={(e) => setZip(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>{t("street")}</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("street")}
                required
                onChange={(e) => setSiteStreet(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>{t("houseNumber")}</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("houseNumber")}
                onChange={(e) => setSiteHouseNr(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setModalIsOpen(false)}>
            {t("cancel")}
          </Button>
          <Button
            variant="primary"
            type="submit"
            onClick={() =>
              handleAddNewSiteClick({
                siteName,
                siteCity,
                zip,
                siteStreet,
                siteHouseNr,
              })
            }
          >
            {t("addSite")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddSite;
