import { useState, useEffect, useCallback } from "react";
import { getTranslations } from "../api/translationApi"; // Adjust the import path as needed
import getBrowserLanguage from "../helpers/getBrowserLanguage";

const useTranslation = (module, useBrowserLanguage = false) => {
  const userLanguage = localStorage.getItem("language") || "hu";
  const initialLanguage = useBrowserLanguage ? getBrowserLanguage() : userLanguage;

  const [translations, setTranslations] = useState({});
  const [language, setLanguage] = useState(initialLanguage);

  useEffect(() => {
    const fetchTranslations = async () => {
      try {
        const response = await getTranslations(language, module);
        if (typeof response === "object" && response !== null) {
          setTranslations(response);
        } else {
          console.error("useTranslation - Invalid translation data:", response);
        }
      } catch (error) {
        console.error("useTranslation - Error fetching translations:", error);
      }
    };
    if (language && module) {
      fetchTranslations();
    } else {
      console.error("useTranslation - Language or module is undefined:", {
        language,
        module,
      });
    }
  }, [language, module]);

  // Memoized function to retrieve translations
  const t = useCallback((key) =>
    typeof translations === "object" && translations !== null && key in translations
      ? translations[key]
      : key
  , [translations]);

  return { t, setLanguage };
};

export default useTranslation;
