/**
 * CompanyEditLocationPage Component
 *
 * This component provides a form for editing location details within a company. It allows the user to
 * update various fields such as location name, zip code, city, street, and house number. The component
 * also provides functionality to delete the location. It uses React hooks for state management and form
 * handling, and React Bootstrap for styling and modals.
 *
 * Props:
 * - fetchUsersAndLocations: Function to fetch users and locations data.
 *
 * Example usage:
 * <CompanyEditLocationPage fetchUsersAndLocations={fetchUsersAndLocations} />
 */

import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  getSiteBySiteId,
  checkSiteNameExists,
  updateSiteBySiteId,
  deleteSiteBySiteId,
} from "../../../../api/locationApi";
import { Form, Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { DeleteOutlined } from "../../../../helpers/icons/antDesignIcons";
import "./CompanyEditLocationPage.scss";
import useTranslation from "../../../../hooks/useTranslation";
import ConfirmationModal from "../../../Shared/ConfirmationModal/ConfirmationModal";

const CompanyEditLocationPage = () => {
  const { locationId } = useParams();
  const { t } = useTranslation("settingsPage");
  const navigate = useNavigate();
  const [locationData, setLocationData] = useState(null);
  const [showDeleteLocationModal, setShowDeleteLocationModal] = useState(false);
  const [errors, setErrors] = useState({});

  const fetchLocationData = async () => {
    try {
      /*       const response = await getSiteBySiteId(locationData.id); */
      const response = await getSiteBySiteId(locationId);
      setLocationData(response.data[0]);
    } catch (error) {
      console.error("Error fetching location info: ", error);
    }
  };

  useEffect(() => {
    fetchLocationData();
  }, [locationId]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setLocationData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Delete location modal handlers
  const handleDeleteLocationModalClose = () =>
    setShowDeleteLocationModal(false);

  const handleDeleteLocationModalShow = () => setShowDeleteLocationModal(true);
  const handleDeleteLocation = async (event) => {
    event.preventDefault();
    // Here you can handle the location deletion, e.g. send a request to a backend
    // API to delete the location with the given ID
    await deleteSiteBySiteId(locationId);
    console.log(`Location with ID ${locationId} has been deleted`);
    navigate("/settings/company");
  };

  // Validate location data
  const validateCompanySiteData = async () => {
    const errors = {};

    // Check if the sitename is already in the database
    const siteNameExists = await checkSiteNameExists(
      locationData.sitename,
      locationData.companies_id,
      locationData._id
    );

    if (siteNameExists) {
      errors.sitenameExists = t("update_site_data_error_sitename_exists");
    }

    if (
      locationData.sitename === "" ||
      locationData.sitename === undefined ||
      locationData.sitename === null ||
      locationData.sitename.length < 3 ||
      locationData.sitename.length > 50
    ) {
      errors.sitenameLength = t("update_site_data_error_sitename_length");
    }

    if (
      locationData.zip.length < 4 ||
      locationData.zip.length > 5 ||
      !/^\d{4,5}$/.test(locationData.zip)
    ) {
      errors.zip = t("update_company_data_error_zip");
    }

    return errors;
  };

  // Handle location edit form submit
  const handleLocationEditSubmit = async (event) => {
    event.preventDefault();

    // Validate location data
    const validationErrors = await validateCompanySiteData();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      Object.values(validationErrors).forEach((error) => {
        toast.error(error);
      });
      return;
    }

    // Update location data
    try {
      // Remove _id from the location data object before sending the request
      const { _id, ...updatedFields } = locationData;
      await updateSiteBySiteId(locationData._id, updatedFields);
      toast.success(t("update_site_data_success"));
    } catch (error) {
      console.error("Error updating location: ", error);
      toast.error(t("update_site_data_error"));
    }

    // Redirect to the company settings page
    navigate("/settings/company");
  };

  // Handle back button click
  const handleEditBackButton = () => {
    navigate("/settings/company");
  };

  if (!locationData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container company-edit-location-page__container navbar-margin">
      <div className="page-title-wrapper">
        <h1 className="page-title">{t("update_site_title")}</h1>
        <div className="page-title-delete__wrapper">
          <p className="page-title-delete_p">{t("delete_site")}</p>
          <button
            className="page-title-delete__button"
            onClick={handleDeleteLocationModalShow}
          >
            <DeleteOutlined />
          </button>
        </div>
      </div>
      <ConfirmationModal
        isVisible={showDeleteLocationModal}
        onConfirm={handleDeleteLocation}
        onCancel={handleDeleteLocationModalClose}
        title={t("delete_site")}
        message={t("delete_site_message")}
      />
      <div className="company-edit-location-page__content">
        <Form onSubmit={handleLocationEditSubmit}>
          <Form.Group className="mb-3" controlId="formBasicLocationName">
            <Form.Label>{t("siteName")}</Form.Label>
            <Form.Control
              type="text"
              placeholder="Telephely neve"
              name="sitename"
              value={locationData.sitename || ""}
              onChange={handleInputChange}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicLocationZip">
            <Form.Label>{t("zip")}</Form.Label>
            <Form.Control
              type="text"
              placeholder={t("zip")}
              name="zip"
              value={locationData?.zip || ""}
              onChange={handleInputChange}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicLocationCity">
            <Form.Label>{t("company_city")}</Form.Label>
            <Form.Control
              type="text"
              placeholder={t("company_city")}
              name="sitecity"
              value={locationData?.sitecity || ""}
              onChange={handleInputChange}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicLocationStreet">
            <Form.Label>{t("company_street")}</Form.Label>
            <Form.Control
              type="text"
              placeholder={t("company_street")}
              name="sitestreet"
              value={locationData?.sitestreet || ""}
              onChange={handleInputChange}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicLocationHouseNr">
            <Form.Label>{t("company_house_number")}</Form.Label>
            <Form.Control
              type="text"
              placeholder={t("company_house_number")}
              name="sitehousenr"
              value={locationData?.sitehousenr || ""}
              onChange={handleInputChange}
            />
          </Form.Group>
          {/*           <Form.Group className="mb-3" controlId="formBasicLocationLeader">
            <Form.Label>Telephelyvezető</Form.Label>
            <Form.Control
              as="select"
              name="locationLeader"
              value={locationData}
                onChange={handleInputChange}
            >
                {locationLeaders.map((leader, index) => (
                    <option key={index} value={leader}>
                        {leader}
                    </option>
                    ))}
            </Form.Control>
          </Form.Group> */}
          <div className="company-edit-user-page__button__wrapper">
            <Button
              variant="primary"
              type="submit"
              className="btn btn-save company-edit-user-page__save-button"
              onClick={handleLocationEditSubmit}
            >
              {t("save")}
            </Button>
            <Button
              variant="secondary"
              onClick={handleEditBackButton}
              className="company-edit-user-back-button"
            >
              {t("cancel")}
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default CompanyEditLocationPage;
