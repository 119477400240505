import { useNavigate } from "react-router-dom";
import axios from "axios";

const useLogout = () => {
  const navigate = useNavigate();
  const backendUrl = process.env.REACT_APP_BACKEND_URL_FOR_FRONTEND;

  const handleLogout = async () => {
    const userId = localStorage.getItem("userId");
    try {
      await axios.post(`${backendUrl}/logout`, { userId });
/*       localStorage.removeItem("token");
      localStorage.removeItem("userId");
      localStorage.removeItem("roles");
      localStorage.removeItem("lastname");
      localStorage.removeItem("firstname");
      localStorage.removeItem("companyname"); */
      localStorage.clear();
      navigate("/");
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  return handleLogout;
};

export default useLogout;