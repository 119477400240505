import useTranslation from './useTranslation';
import USER_ROLES from '../constants/userRoles';

const TranslatedRoles = () => {
  const { t } = useTranslation("common");

  return {
    [USER_ROLES.SUPER_ADMIN]: t('SUPER_ADMIN'),
    [USER_ROLES.COMPANY_ADMIN]: t('COMPANY_ADMIN'),
    [USER_ROLES.COMPANY_LOCATION_LEADER]: t('COMPANY_LOCATION_LEADER'),
    [USER_ROLES.COMPANYUSER]: t('COMPANYUSER'),
    [USER_ROLES.RESELLER]: t('RESELLER')
  };
};

export default TranslatedRoles;