/**
 * ListingTable Component
 *
 * This component renders a table with sortable columns and various interactive elements.
 * It displays data passed to it via props and provides functionalities such as sorting,
 * navigating to detailed views, and acknowledging alerts. The component uses React hooks
 * for state management and navigation.
 *
 * Props:
 * - tableHeaders: An object representing the headers of the table.
 * - tableData: An array of data objects to be displayed in the table.
 * - rowLimit: A number to limit the number of rows displayed in the table.
 * - handleAcknowledge: A function to handle the acknowledgment of alerts.
 * - unit: A string representing the unit of measurement (e.g., "Celsius").
 * - convertToFahrenheit: A function to convert Celsius to Fahrenheit.
 *
 * Example usage:
 * <ListingTable
 *   tableHeaders={tableHeaders}
 *   tableData={tableData}
 *   rowLimit={10}
 *   handleAcknowledge={handleAcknowledge}
 *   unit="Celsius"
 *   convertToFahrenheit={convertToFahrenheit}
 * />
 */

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import DashboardListMoreInfoModal from "../../DashboardPage/DashboardListMoreInfoModal/DashboardListMoreInfoModal";
import moment from "moment-timezone";
import "moment/locale/hu";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import "./ListingTable.scss";
import {
  AlertOutlined,
  CheckCircleOutlined,
  InfoCircleOutlined,
  SortAscendingOutlined,
} from "@ant-design/icons";
import useTranslation from "../../../hooks/useTranslation";

const ListingTable = ({
  tableHeaders,
  tableData,
  rowLimit,
  handleAcknowledge,
  unit,
  convertToFahrenheit,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation("dashboardPage");
  // State for controlling the order of the table data
  const [isReversed, setIsReversed] = useState(false);
  const [showMoreInfoModal, setShowMoreInfoModal] = useState(false);
  const [selectedAlert, setSelectedAlert] = useState(null);

  // If there's no data, display a message
  if (tableData.length === 0) {
    return <div className="no-data">{t("dashboard_table_message")}</div>;
  }

  // Get the keys from the tableHeaders object
  const headerKeys = Object.keys(tableHeaders);

  // Limit the table data if rowLimit is a positive integer
  const limitedTableData =
    Number.isInteger(rowLimit) && rowLimit > 0
      ? tableData.slice(0, rowLimit)
      : tableData;

  // Reverse the table data if isReversed is true
  const sortedTableData = isReversed
    ? [...limitedTableData].reverse()
    : limitedTableData;

  // Function to toggle the isReversed state
  const handleSort = () => {
    setIsReversed(!isReversed);
  };

  const showMoreInfoModalClick = (alert) => {
    setSelectedAlert(alert);
    setShowMoreInfoModal(true);
  };

  const goToDeviceMoreInfo = (device) => {
    navigate(`/devices/${device.probeid}`, { state: { device } });
  };

  const getThresholdClass = (value, minValue, maxValue, key) => {
    if (key === "min_value" && value < minValue) {
      return "min-overrun bounce"; // Below min value
    } else if (key === "max_value" && value > maxValue) {
      return "max-overrun bounce"; // Above max value
    }
    return ""; // No class if within range
  };
  /*   const goToLocationMoreInfo = (container) => {
    console.log("Container Data:", container);
    navigate(`/locations/${container._id}`, {
      state: { container },
    });
  };
 */
  return (
    <Table>
      <thead>
        <tr className="table-header-tr">
          {headerKeys.map((key, index) => (
            <th
              key={index}
              className={`table-header__item ${key}-header`}
              id={`${key}-header`}
            >
              <p className="table-header__title">{tableHeaders[key]}</p>
              {(() => {
                switch (key) {
                  case "moreInfoModalButton":
                    return null;
                  case "acknowledgedButton":
                    return null;
                  /*  default:
                    return (
                      <Button
                        variant="link"
                        onClick={handleSort}
                        className="table-header-sort__btn"
                      >
                        <SortAscendingOutlined />
                      </Button>
                    ); */
                }
              })()}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {sortedTableData.map((data, index) => (
          <tr key={index} className="dashboard-alert-table-row">
            {headerKeys.map((key, cellIndex) => (
              <td
                key={cellIndex}
                className={`table-data ${key}-data`}
                id={`${key}-data`}
              >
                <p className="table-data__p ">
                  {(() => {
                    switch (key) {
                      case "probeid":
                        return (
                          <span
                            /*                to={{
                            pathname: `/devices/${data[key]}`,
                            state: { device: data },
                          }} */
                         className="table-link-button"
                            onClick={() => goToDeviceMoreInfo(data)}
                          >
                            {data[key]}
                          </span>
                        );
                      case "containername":
                        return (
                          <span /* onClick={() => goToLocationMoreInfo(data)} */
                          >
                            {data[key]}
                          </span>
                        );

                      case "status":
                        return data[key] === "OK" ? (
                          <CheckCircleOutlined style={{ color: "green" }} />
                        ) : (
                          <AlertOutlined style={{ color: "red" }} />
                        );
                      case "sens_value":
                        return (
                          <span style={{ color: "red" }} className="bounce">
                            <AlertOutlined style={{ color: "red" }} />
                            {unit === "Celsius"
                              ? data[key].toFixed(2) + "°C"
                              : convertToFahrenheit(data[key]).toFixed(2) +
                                "°F"}
                          </span>
                        );
                      case "min_value":
                      case "max_value":
                        const className = getThresholdClass(
                          data.sens_value,
                          data.min_value,
                          data.max_value,
                          key
                        );
                        console.log(
                          `Key: ${key}, Value: ${data.sens_value}, Min: ${data.min_value}, Max: ${data.max_value}, Class: ${className}`
                        );
                        return (
                          <span className={className}>
                            {unit === "Celsius"
                              ? data[key].toFixed(2) + "°C"
                              : convertToFahrenheit(data[key]).toFixed(2) +
                                "°F"}
                          </span>
                        );
                      case "sens_timestamp":
                        return (
                          <span style={{ color: "black" }}>
                            {moment(data[key])
                              .tz(
                                Intl.DateTimeFormat().resolvedOptions().timeZone
                              )
                              .format("YYYY.MM.DD HH:mm")}
                          </span>
                        );
                      case "moreInfoModalButton":
                        return (
                          <Button
                            variant=""
                            onClick={() => {
                              showMoreInfoModalClick(data);
                            }}
                          >
                            <InfoCircleOutlined />
                          </Button>
                        );

                      case "acknowledgedButton":
                        return (
                          <Button
                            variant="success"
                            onClick={() => handleAcknowledge(data)}
                          >
                            <CheckCircleOutlined />
                          </Button>
                        );

                      default:
                        return data[key];
                    }
                  })()}
                </p>
              </td>
            ))}
          </tr>
        ))}
      </tbody>
      <DashboardListMoreInfoModal
        show={showMoreInfoModal}
        handleClose={() => setShowMoreInfoModal(false)}
        dashboardListItemData={selectedAlert}
      />
    </Table>
  );
};

export default ListingTable;
