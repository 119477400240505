import axios from "axios";
const backendUrl = process.env.REACT_APP_BACKEND_URL_FOR_FRONTEND;
const token = localStorage.getItem("token");

// Function to get site name by site id for placing in the report file name
export const getSiteNameBySiteId = async (siteId) => {
  try {
    const response = await axios({
      url: `${backendUrl}/locations/sites/name/${siteId}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data.name;
  } catch (error) {
    console.error(error);
    throw new Error(error.message);
  }
};

// Function to get container name by container id for placing in the report file name
export const getContainerNameByContainerId = async (containerId) => {
  try {
    const response = await axios({
      url: `${backendUrl}/locations/containers/name/${containerId}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data.name;
  } catch (error) {
    console.error(error);
    throw new Error(error.message);
  }
};

// Function to get measurement reports for the given duration
const getMeasurementReportsByDuration = async (
  companyId,
  probesensorsObj,
  duration,
  companyName,
  siteName,
  containerName,
  dateFrom,
  dateTo,
  language
) => {
  try {
    // Correctly access the Set within the probesensors object
    const probesensors = probesensorsObj.probesensors;
    // Check if probesensors is not empty
    if (probesensors.size === 0) {
      throw new Error("No probe sensors provided");
    }

    // Convert Set to Array and join with commas
    const probesensorsQuery = Array.from(probesensors).join(",");

    if (probesensorsQuery === undefined) {
      console.warn("probesensorsQuery is undefined");
    }

    if (!probesensorsQuery) {
      console.warn("probesensorsQuery is empty or undefined");
    }

    // Construct the query string with additional parameters
    const queryParams = new URLSearchParams({
      probesensors: probesensorsQuery,
      lang: language,
      companyName,
      siteName,
      containerName,
      dateFrom,
      dateTo,
    }).toString();

    const response = await axios({
      url: `${backendUrl}/reports/${duration}/${companyId}?${queryParams}`,
      method: "GET",
      responseType: "blob", // Important for handling binary data like CSV
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    // Extract the filename from the Content-Disposition header
    const contentDisposition =
      response.headers["content-disposition"] ||
      response.headers["Content-Disposition"];
    let filename = "report.zip";
    if (contentDisposition) {
      const match = contentDisposition.match(/filename="(.+)"/);
      if (match) {
        filename = match[1];
      }
    }

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // Clean up
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return "No data found for the selected date range";
    } else {
      console.error(error);
      throw new Error(error.message);
    }
  }
};

// Function to get measurement reports for the last 24 hours
export const getMeasurementReportsForSelectedDevicesForTheLast24hByCompanyId =
  async (
    companyId,
    probesensorsObj,
    companyName,
    siteName,
    containerName,
    probeId,
    dateFrom,
    dateTo,
    language
  ) => {
    await getMeasurementReportsByDuration(
      companyId,
      probesensorsObj,
      "last24h",
      companyName,
      siteName,
      containerName,
      probeId,
      dateFrom,
      dateTo,
      language
    );
  };

// Function to get measurement reports for the last week
export const getMeasurementReportsForSelectedDevicesForTheLastWeekByCompanyId =
  async (
    companyId,
    probesensorsObj,
    companyName,
    siteName,
    containerName,
    probeId,
    dateFrom,
    dateTo,
    language
  ) => {
    await getMeasurementReportsByDuration(
      companyId,
      probesensorsObj,
      "lastweek",
      companyName,
      siteName,
      containerName,
      probeId,
      dateFrom,
      dateTo,
      language
    );
  };

// Function to get measurement reports for the last month
export const getMeasurementReportsForSelectedDevicesForTheLastMonthByCompanyId =
  async (
    companyId,
    probesensorsObj,
    companyName,
    siteName,
    containerName,
    probeId,
    dateFrom,
    dateTo,
    language
  ) => {
    await getMeasurementReportsByDuration(
      companyId,
      probesensorsObj,
      "lastmonth",
      companyName,
      siteName,
      containerName,
      probeId,
      dateFrom,
      dateTo,
      language
    );
  };

// Function to get measurement reports for the last quarter
export const getMeasurementReportsForSelectedDevicesForTheLastQuarterByCompanyId =
  async (
    companyId,
    probesensorsObj,
    companyName,
    siteName,
    containerName,
    probeId,
    dateFrom,
    dateTo,
    language
  ) => {
    await getMeasurementReportsByDuration(
      companyId,
      probesensorsObj,
      "lastquarter",
      companyName,
      siteName,
      containerName,
      probeId,
      dateFrom,
      dateTo,
      language
    );
  };

// Function to get measurement reports for the last half year
export const getMeasurementReportsForSelectedDevicesForTheLastHalfYearByCompanyId =
  async (
    companyId,
    probesensorsObj,
    companyName,
    siteName,
    containerName,
    probeId,
    dateFrom,
    dateTo,
    language
  ) => {
    await getMeasurementReportsByDuration(
      companyId,
      probesensorsObj,
      "lasthalfyear",
      companyName,
      siteName,
      containerName,
      probeId,
      dateFrom,
      dateTo,
      language
    );
  };

// Function to get measurement reports for the last year
export const getMeasurementReportsForSelectedDevicesForTheLastYearByCompanyId =
  async (
    companyId,
    probesensorsObj,
    companyName,
    siteName,
    containerName,
    probeId,
    dateFrom,
    dateTo,
    language
  ) => {
    await getMeasurementReportsByDuration(
      companyId,
      probesensorsObj,
      "lastyear",
      companyName,
      siteName,
      containerName,
      probeId,
      dateFrom,
      dateTo,
      language
    );
  };

// Function to get measurement reports for a custom date range
export const getMeasurementReportsForSelectedDevicesForCustomDateRangeByCompanyId =
  async (
    companyId,
    probesensorsObj,
    companyName,
    siteName,
    containerName,
    dateFrom,
    dateTo,
    language
  ) => {
    try {
      // Correctly access the Set within the probesensors object
      const probesensors = probesensorsObj.probesensors;
      // Check if probesensors is not empty
      if (probesensors.size === 0) {
        throw new Error("No probe sensors provided");
      }

      // Convert Set to Array and join with commas
      const probesensorsQuery = Array.from(probesensors).join(",");
      if (probesensorsQuery === undefined) {
        console.warn("probesensorsQuery is undefined");
      }
      if (!probesensorsQuery) {
        console.warn("probesensorsQuery is empty or undefined");
      }

      // Construct the query string with additional parameters
      const queryParams = new URLSearchParams({
        probesensors: probesensorsQuery,
        lang: language,
        companyName,
        siteName,
        containerName,
        startDate: dateFrom,
        endDate: dateTo,
      }).toString();

      const response = await axios({
        url: `${backendUrl}/reports/customtimerange/${companyId}?${queryParams}`,
        method: "GET",
        responseType: "blob", // Important for handling binary data like CSV
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Extract the filename from the Content-Disposition header
      const contentDisposition = response.headers["content-disposition"];
      let filename = "report.zip";
      if (contentDisposition) {
        const match = contentDisposition.match(/filename="(.+)"/);
        if (match) {
          filename = match[1];
        }
      }

      const url = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement("a");

      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // Clean up
    } catch (error) {
      if (error.response && error.response.status === 404) {
        return "No data found for the selected date range";
      } else {
        return `Error fetching reports: ${error.message}`;
      }
    }
  };
